import { styled } from "@mui/system";
import { Button, Typography } from "@mui/material";
import palette from "../../../theme/palette";

export const PrimaryButtonCustom = styled(Button)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    borderRadius: "8px",
    height: "49px",

    "&.large": {
      width: "254px",
    },

    "&.medium ": {
      width: "155px",
      height: "49px",
    },

    "&.small": {
      width: "270px",
      height: "40px",
    },

    "&.blue": {
      backgroundColor: palette.blue.light,
      "&&:hover": {
        backgroundColor: palette.blue.main,
      },  
      "&&:disabled": {
        backgroundColor: palette.blue.main,
      },
    },

    "&.red": {
      backgroundColor: palette.red.light,
      "&&:hover": {
        backgroundColor: palette.red.main,
      },
      "&&:disabled": {
        backgroundColor: palette.red.main,
      },
    },

    "&.white": {
      backgroundColor: palette.white.light,
      boxShadow: "0px 4px 8px -6px rgba(1, 37, 50, 0.03), 0px 16px 8px -4px rgba(1, 37, 50, 0.03)",
      border: "0.5px solid #D9D9D9",
      "&&:hover": {
        backgroundColor: palette.black.main_5,
      },
      "&&:disabled": {
        backgroundColor: palette.black.main_5,
      },
    },

    marginLeft: "12px",
  ["@media (max-width: 1260px)"]: {
    width: "270px",
    marginLeft: "0px",
    maxWidth: "100%"
  },
  ["@media (max-width: 899x)"]: {
    width: "100%",
    marginLeft: "0px",
    maxWidth: "100%"
  },
});