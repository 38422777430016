import { Navigate, Outlet } from "react-router";
import React from "react";

const useAuth = () => {
  return localStorage.getItem("isAuthConexte");
};

export const PrivateRoute = () => {
  const isAuthConexte = useAuth();
  return isAuthConexte ? <Outlet /> : <Navigate to="/" />;
};

export const AuthRoute = () => {
  const isAuthConexte = useAuth();
  return isAuthConexte ? <Navigate to="/feed" /> : <Outlet />;
}


