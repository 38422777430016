import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";

const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    duplicatePostSuccess: (state, actions) => {
      state.isLoading = false;
      state.error = "";
      state.duplicatePost = actions.payload;
    },
    duplicatePostReset: (state) => {
      state.duplicatePost = initialState.post;
    },
    postReset: (state) => {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.duplicatePost = initialState.post;
    }
  },
});

const { reducer, actions } = postSlice;

export const {
  duplicatePostSuccess,
  duplicatePostReset,
  postReset
} = actions;


export const postActions = {
  duplicatePostSuccess,
  duplicatePostReset,
  postReset
}

export default reducer;
