import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        loginPending: (state) => {
            state.isLoading = true;
            state.error = "";
            state.isSuccess= false
        },
        loginSuccess: (state) => {
            state.isLoading = false;          
            state.error = "";
            state.isSuccess= true
        },
        loginFailure: (state) => {
            state.isLoading = false;
            state.error = actions.payload;
            state.isSuccess= false;
        },
        loginReset: (state) => {
            state.isLoading = initialState.isLoading;
            state.error = initialState.error;
            state.isSuccess = initialState.isSuccess;
        },
    }
});

const { reducer, actions } = loginSlice

export const { 
    loginPending, 
    loginSuccess, 
    loginFailure,
    loginReset 
} = actions;

export const loginActions = {
    loginPending,
    loginSuccess,
    loginFailure,
    loginReset
}

export default reducer;