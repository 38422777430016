import React, { useEffect, useState } from "react";
import * as style from "./styles";
import * as font from "../../../../theme/typography";
import * as button from "../../../../components/buttons";
import { Box, Grid } from "@mui/material";
import { CardSection1IngenieurLogiciel, CardSection1IngenieurLogicielMobile, TalentsAssociationsMobile, TalentsAssos, TalentsFreelances, TalentsFreelancesMobile, TalentsInterim, TalentsInterimMobile, TalentsJoboards, TalentsJoboardsMobile, TalentsPrestas, TalentsRecruteurs, TalentsRecruteursMobile, TalentsSousTraitantsMobile } from "../../../../assets";
import palette from "../../../../theme/palette";
import { Dash } from "../../../../components/styles/dash";
import { NeedPill } from "../../../../components/animations/need-pills";
import { ResponsePill } from "../../../../components/animations/response-pills";
import { navigateSignIn, navigateSignUp } from "../../../../utils/navigate";
import { useNavigate } from "react-router-dom";
import Gradient from 'rgt';
import { validateIsEmail } from "../../../../utils/helpers/validate-is-email";
import { NewsLetterApi } from "../../../../api";

export const HomeSection1Mobile = (props) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 899);
  const updateMedia = () => {
    setIsMobile(window.innerWidth < 899);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const [doPillsAppear, setDoPillsAppear] = useState(false);
  const [straightDash, setStraightDash] = useState([]);
  const [bifurcationInterimDash, setBifurcationInterimDash] = useState([]);
  const [bifurcationRecruteursDash, setBifurcationRecruteursDash] = useState([]);  
  const [bifurcationSousTraitantsDash, setBifurcationSousTraitantsDash] = useState([]);
  const [bifurcationAssociationsDash, setBifurcationAssociationsDash] = useState([]);
  const [bifurcationFreelancesDash, setBifurcationFreelancesDash] = useState([]);
  const [bifurcationJoboardDash, setBifurcationJoboardDash] = useState([]);
  const [email, setEmail] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [isEmailCorrect, setIsEmailCorrect] = useState(false);
  const [isEmailSend, setIsEmailSend] = useState(false);
  const [textError, setTextError] = useState("Email invalide");

  const inscription = () => {
    if(email) {
      if (validateIsEmail(email)) {
        setIsEmailError(false);
        setIsEmailCorrect(true);
        NewsLetterApi.create({email})
        .then(()=> {
          setIsEmailSend(true)
          setEmail("")
        })
        .catch(()=> {
          setTextError("E-mail déjà enregistré");
          setIsEmailError(true);
          setIsEmailCorrect(false);
          setTimeout(() => {
            setIsEmailError(false);
          }, 10000);
        })
      } else {
        setTextError("Email invalide");
        setIsEmailError(true);
        setIsEmailCorrect(false);
        setTimeout(() => {
          setIsEmailError(false);
        }, 10000);
      }
    }
  }

  // starting loop of pills and requests
  useEffect(() => {
    setTimeout(() => setDoPillsAppear(true), 8000)
  }, [])
  // construction of the linear strokes
  useEffect(() => {
    let arrayDash = []
    let numDash = isMobile ? 91 : 81;
    let top = isMobile ? "35px" : "50px";
    for ( let i = 0 ; i <= numDash ; i++) {
      if (i < 24 || i > 34) {
        arrayDash = [...arrayDash, <Dash 
          key={i} 
          index={i} 
          initial={10} 
          vertical="true"
          top={`calc(${(i * 3) + 4.5}% + ${top})`}
        />]
      }
    } 
    setStraightDash(arrayDash)
  }, [])
  // construction of the bifurcation interim strokes
  // if you want to make a curve, use parabola calculation
  useEffect(() => {
    let arrayDash = []
    let deg = isMobile ? "40deg" : "13deg"
    for ( let i = 0 ; i <= 18 ; i++) {
      arrayDash = [...arrayDash, <Dash 
        key={i} 
        index={i} 
        initial={60} 
        top={`calc(${100 + 1.5 * i}% + 188px)`}
        left={`calc(${51 + 2 * i}%)`}
        rotate={deg}
      />]
    } 
    setBifurcationInterimDash(arrayDash)
  }, [isMobile])
  // construction of the bifurcation recruteurs strokes
  // if you want to make a curve, use parabola calculation
  useEffect(() => {
    let arrayDash = []
    let deg = isMobile ? "-40deg" : "-13deg"
    for ( let i = 0 ; i <= 18 ; i++) {
      arrayDash = [...arrayDash, <Dash 
        key={i} 
        index={i} 
        initial={60} 
        top={`calc(${100 + 1.5 * i}% + 188px)`}
        left={`calc(${48.5 - 2 * i}% - 1px)`}
        rotate={deg}
      />]
    } 
    setBifurcationRecruteursDash(arrayDash)
  }, [isMobile])
  // construction of the bifurcation sous traitants strokes
  // if you want to make a curve, use parabola calculation
  useEffect(() => {
    let arrayDash = []
    let deg = isMobile ? "40deg" : "13deg"
    for ( let i = 0 ; i <= 18 ; i++) {
      arrayDash = [...arrayDash, <Dash 
        key={i} 
        index={i} 
        initial={60} 
        top={`calc(${100 + 1.5 * i}% + 375px)`}
        left={`calc(${51 + 2 * i}%)`}
        rotate={deg}
      />]
    } 
    setBifurcationSousTraitantsDash(arrayDash)
  }, [isMobile])
  // construction of the bifurcation associations strokes
  // if you want to make a curve, use parabola calculation
  useEffect(() => {
    let arrayDash = []
    let deg = isMobile ? "-40deg" : "-13deg"
    for ( let i = 0 ; i <= 18 ; i++) {
      arrayDash = [...arrayDash, <Dash 
        key={i} 
        index={i} 
        initial={60} 
        top={`calc(${100 + 1.5 * i}% + 375px)`}
        left={`calc(${48.5 - 2 * i}% - 1px)`}
        rotate={deg}
      />]
    } 
    setBifurcationAssociationsDash(arrayDash)
  }, [isMobile])
  // construction of the bifurcation sous freelances strokes
  // if you want to make a curve, use parabola calculation
  useEffect(() => {
    let arrayDash = []
    let deg = isMobile ? "40deg" : "13deg"
    for ( let i = 0 ; i <= 18 ; i++) {
      arrayDash = [...arrayDash, <Dash 
        key={i} 
        index={i} 
        initial={60} 
        top={`calc(${100 + 1.5 * i}% + 565px)`}
        left={`calc(${51 + 2 * i}%)`}
        rotate={deg}
      />]
    } 
    setBifurcationFreelancesDash(arrayDash)
  }, [isMobile])
  // construction of the bifurcation joboards strokes
  // if you want to make a curve, use parabola calculation
  useEffect(() => {
    let arrayDash = []
    let deg = isMobile ? "-40deg" : "-13deg"
    for ( let i = 0 ; i <= 18 ; i++) {
      arrayDash = [...arrayDash, <Dash 
        key={i} 
        index={i} 
        initial={60} 
        top={`calc(${100 + 1.5 * i}% + 565px)`}
        left={`calc(${48.5 - 2 * i}% - 1px)`}
        rotate={deg}
      />]
    } 
    setBifurcationJoboardDash(arrayDash)
  }, [isMobile])

  return (
    <style.ContainerSection1 {...props} container={true} 
      flexDirection="column"
      alignItems="center"
      mt={2.5}
      ml="auto"
      mr="auto"
      maxWidth={{xs: "320px"}}
    >
      <style.CardTop>
        <style.CardTopWhite>
          <style.CardTopImage src={CardSection1IngenieurLogicielMobile}/>
          <Grid container flexDirection="column">
            <style.CardTopText>
              Votre entreprise
            </style.CardTopText>
            <style.CardTopDescription>
              10 postes ouverts
            </style.CardTopDescription>
          </Grid>
        </style.CardTopWhite>
        <style.CardTopRed>
          Ingénieur logiciel
        </style.CardTopRed>
      </style.CardTop>

      <style.YllonaLogo>
        <Gradient dir="left-to-right" from="#FF30A0" to="#FA194E">
            Conexte
        </Gradient>
      </style.YllonaLogo>

      {/* <Box pl={5} pr={5} mt={2}>
        <font.P12 color={palette.green.dark_2} textAlign="center">La première place de marché qui centralise la recherche et l'intégration de vos futurs talents.</font.P12>
      </Box>      */}

        <font.P20 fontSize="24px" color="#011E36" fontWeight="500" sx={{textAlign: "left", width: "270px", marginTop: 3,  "& b": {color: "#F03964", fontWeight: "500", }}}>
          Bientôt <b>disponible</b>
        </font.P20>
          <Grid position="relative">
            <Box mt={1} mb={1}>
              <style.InputCustom 
                placeholder="Votre email"
                maxLength="150"
                name="email"
                onChange={(e) => {
                  setIsEmailError(false)
                  setEmail(e.target.value)
                }}
                validators={["required", "isEmail"]}
                variant="outlined" 
                sx={
                  (isEmailSend && {
                    "& .MuiOutlinedInput-root fieldset": {
                      border: "1px solid " + palette.green.light + " !important"
                    }
                  }) 
                  ||
                  (isEmailError && {
                    "& .MuiOutlinedInput-root fieldset": {
                      border: "1px solid " + palette.red.main + " !important"
                    }
                  }) 
                  || 
                  (isEmailCorrect && {
                    "& .MuiOutlinedInput-root fieldset": {
                      border: "1px solid #862165 !important"
                    }
                  })
                }
              />
            </Box>
            {isEmailSend && (
              <Box position="absolute" left="0" top="calc(100% - 10px)">
                <font.P12 color={palette.green.light}>Envoyé</font.P12>
              </Box>
            )}
            {isEmailError && (
              <Box position="absolute" left="0" top="calc(100% - 10px)">
                <font.P12 color={palette.red.main}>{textError}</font.P12>
              </Box>
            )}
          </Grid>
          <Box mt={1} mb={-8} fullwidth>
            <button.PrimaryButton 
              dye="red" 
              size="small" 
              sx={{
                zIndex: 999999999,
                width: "100%", 
                padding: "15px 12px",

                ["@media (max-width: 1260px)"]: {
                  marginRight: 0, 
                },
              }}
              onClick={() => inscription()}
            >
              <font.P16 color={palette.white.pattern} fontWeight="700" sx={{whiteSpace: "nowrap"}}>
                Me pré inscrire
              </font.P16>
            </button.PrimaryButton>
          </Box>

      <style.RedLine />
      <style.ContainerBottomCards container={true}>
        <style.ContainerTwoBottomCards container={true} pl={2} pr={2} mb={9}>
          <style.CardTalent>
            <style.NumCard>+400</style.NumCard>
            <style.ImageCardTalent src={TalentsRecruteurs}/>
            <style.NameTalent>Recruteurs</style.NameTalent>
            <style.DescriptionTalent>Talents disponibles</style.DescriptionTalent>
          </style.CardTalent>
          <style.CardTalent>
            <style.NumCard>+1000</style.NumCard>
            <style.ImageCardTalent src={TalentsInterim}/>
            <style.NameTalent>Interim</style.NameTalent>
            <style.DescriptionTalent>Talents disponibles</style.DescriptionTalent>
          </style.CardTalent>
        </style.ContainerTwoBottomCards>
        <style.ContainerTwoBottomCards container={true} pl={2} pr={2} mb={9}>
          <style.CardTalent>
            <style.NumCard>+200</style.NumCard>
            <style.ImageCardTalent src={TalentsAssos}/>
            <style.NameTalent>Associations</style.NameTalent>
            <style.DescriptionTalent>Talents disponibles</style.DescriptionTalent>
          </style.CardTalent>
          <style.CardTalent>
            <style.NumCard>+3000</style.NumCard>
            <style.ImageCardTalent src={TalentsPrestas}/>
            <style.NameTalent>Sous traitants</style.NameTalent>
            <style.DescriptionTalent>Talents disponibles</style.DescriptionTalent>
          </style.CardTalent>
        </style.ContainerTwoBottomCards>
        <style.ContainerTwoBottomCards container={true} pl={2} pr={2} mb={9}>
          <style.CardTalent>
            <style.NumCard>+10k</style.NumCard>
            <style.ImageCardTalent src={TalentsJoboards}/>
            <style.NameTalent>Joboards</style.NameTalent>
            <style.DescriptionTalent>Talents disponibles</style.DescriptionTalent>
          </style.CardTalent>
          <style.CardTalent>
            <style.NumCard>+300</style.NumCard>
            <style.ImageCardTalent src={TalentsFreelances}/>
            <style.NameTalent>Freelances</style.NameTalent>
            <style.DescriptionTalent>Talents disponibles</style.DescriptionTalent>
          </style.CardTalent>
        </style.ContainerTwoBottomCards>
      </style.ContainerBottomCards>
      
      {/* stroke rendering */}
      <Box>
        {straightDash.map(item => item)}
        {bifurcationInterimDash.map(item => item)}
        {bifurcationRecruteursDash.map(item => item)}
        {bifurcationSousTraitantsDash.map(item => item)}
        {bifurcationAssociationsDash.map(item => item)}
        {bifurcationFreelancesDash.map(item => item)}
        {bifurcationJoboardDash.map(item => item)}
      </Box>
      {/* rendering of the pills */}
      {doPillsAppear && (
        <Box>
          <NeedPill animationPill={style.AnimationNeedPill}/>
          <ResponsePill animationPill={style.AnimationResponsePill}/>
          {isMobile && (
            <Box>
              <NeedPill animationPill={style.AnimationInterimNeedPillMobile} small={true}/>
              <ResponsePill animationPill={style.AnimationInterimResponsePillMobile} small={true}/>

              <NeedPill animationPill={style.AnimationRecruteursNeedPillMobile} small={true}/>
              <ResponsePill animationPill={style.AnimationRecruteursResponsePillMobile} small={true}/>

              <NeedPill animationPill={style.AnimationSousTraitantsNeedPillMobile} small={true}/>
              <ResponsePill animationPill={style.AnimationSousTraitantsResponsePillMobile} small={true}/>

              <NeedPill animationPill={style.AnimationAssociationsNeedPillMobile} small={true}/>
              <ResponsePill animationPill={style.AnimationAssociationsResponsePillMobile} small={true}/>

              <NeedPill animationPill={style.AnimationFreelancesNeedPillMobile} small={true}/>
              <ResponsePill animationPill={style.AnimationFreelancesResponsePillMobile} small={true}/>

              <NeedPill animationPill={style.AnimationJoboardNeedPillMobile} small={true}/>
              <ResponsePill animationPill={style.AnimationJoboardsResponsePillMobile} small={true}/>
            </Box>  
          )}
        </Box>
      )}
    </style.ContainerSection1>
  )
}