export default {
  grey: {
    main_1: "0px 4px 2px -2px rgba(27, 46, 94, 0.02)",
    main_2: "0px 4px 8px -2px rgba(27, 46, 94, 0.04)",
    main_3: "0px 2px 4px -2px rgba(27, 46, 94, 0.08)",
    main_4: "0px 4px 8px -6px rgba(27, 46, 94, 0.03), 0px 16px 8px -4px rgba(27, 46, 94, 0.03)",
    main_5: "0px 8px 24px -4px rgba(1, 37, 50, 0.08)",
    main_6: "0px 8px 24px -4px rgba(27, 46, 94, 0.12)",
    inner: "inset 0px 1px 2px rgba(27, 46, 94, 0.3)",
  },
  blue: {
    main_1: "0px 4px 2px -2px rgba(78, 115, 248, 0.02);",
    main_2: "0px 4px 8px -2px rgba(78, 115, 248, 0.04)",
    main_3: "0px 2px 4px -2px rgba(78, 115, 248, 0.08)",
    main_4: "0px 4px 8px -6px rgba(78, 115, 248, 0.03), 0px 16px 8px -4px rgba(78, 115, 248, 0.03)",
    main_5: "0px 8px 24px -4px rgba(1, 37, 50, 0.08)",
    main_6: "0px 0px 8px rgba(89, 124, 250, 0.32)",
    inner: "inset 0px 1px 2px rgba(62, 69, 239, 0.3)",
  },
  popup: {
    main_1: "0px 4px 4px -4px rgba(0, 0, 0, 0.08), 0px 8px 24px -4px rgba(0, 0, 0, 0.08)"
  },
  dark: {
    main_5: "0px 8px 24px -4px rgba(27, 46, 94, 0.08)"
  }
}