import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
      authSuccess: (state, actions)=> {
        state.token = actions.payload
      },
      authReset: (state) => {
        state.token = initialState.token
        state.userId = initialState.userId
      },
      recoverPasswordSuccess: (state, actions)=> {
        state.userId = actions.payload
      },
  },
});

const { reducer, actions } = authSlice;

export const {
  authSuccess,
  authReset,
  recoverPasswordSuccess,
} = actions;


export const authActions = {
  authSuccess,
  authReset,
  recoverPasswordSuccess,
}

export default reducer;
