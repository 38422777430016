import React from "react";
import * as style from "./styles";
import * as font from "../../../theme/typography";
import palette from "../../../theme/palette";

/* 
  Required parameters:
    id: component id
    size: the possible sizes of the button (large, default, medium or small)
    dye: parameter to define the color that will be displayed (blue or red)

  Extra parameters:
    onClick: action when clicking the button
    disabled: boolean informing whether the button is disabled or not

    children: content inside the component
      <Component>What stay here is the "children"</Component>
*/

export const PrimaryButton = (props) => {

  const classe = props.size + " " + props.dye;

  return (
    <style.PrimaryButtonCustom 
      {...props}
      type="submit" 
      onClick={props.onClick}
      disabled={props.disabled || false}
      className={classe}      
      p={0}
      width={props.width || "202px"}
      sx={props.sx || ""}
    >
          {props.children || ""}

    </style.PrimaryButtonCustom>
  );
};