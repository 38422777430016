import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { 
  CardSection1IngenieurDetails,
  CardSection1IngenieurLogiciel, 
  HomeBallSection1, 
  TalentsAssos, 
  TalentsFreelances, 
  TalentsInterim, 
  TalentsJoboards, 
  TalentsPrestas, 
  TalentsRecruteurs, 
} from "../../../../assets";
import * as style from "./styles";
import * as font from "../../../../theme/typography";
import * as button from "../../../../components/buttons";
import palette from "../../../../theme/palette";
import { Dash } from "../../../../components/styles/dash";
import { NeedPill } from "../../../../components/animations/need-pills";
import { ResponsePill } from "../../../../components/animations/response-pills";
import { useNavigate } from "react-router-dom";
import Gradient from 'rgt';
import { navigateSignIn, navigateSignUp } from "../../../../utils/navigate";
import { validateIsEmail } from "../../../../utils/helpers/validate-is-email";
import { NewsLetterApi } from "../../../../api";

export const HomeSection1 = (props) => {
  const navigate = useNavigate();
  const [doPillsAppear, setDoPillsAppear] = useState(false)

  const [straightDash, setStraightDash] = useState([]);
  const [bifurcationInterimDash, setBifurcationInterimDash] = useState([]);
  const [bifurcationRecruteursDash, setBifurcationRecruteursDash] = useState([]);
  const [bifurcationPrestasDash, setBifurcationPrestasDash] = useState([]);
  const [bifurcationJoboardsDash, setBifurcationJoboardsDash] = useState([]);
  const [bifurcationFreelancesDash, setBifurcationFreelancesDash] = useState([]);
  const [bifurcationAssosDash, setBifurcationAssosDash] = useState([]);
  const [email, setEmail] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [isEmailCorrect, setIsEmailCorrect] = useState(false);
  const [isEmailSend, setIsEmailSend] = useState(false);
  const [textError, setTextError] = useState("Email invalide");

  const inscription = () => {
    if(email) {
      if (validateIsEmail(email)) {
        setIsEmailError(false);
        setIsEmailCorrect(true);
        NewsLetterApi.create({email})
        .then(()=> {
          setIsEmailSend(true)
          setEmail("")
        })
        .catch(()=> {
          setTextError("E-mail déjà enregistré");
          setIsEmailError(true);
          setIsEmailCorrect(false);
          setTimeout(() => {
            setIsEmailError(false);
          }, 10000);
        })
      } else {
        setTextError("Email invalide");
        setIsEmailError(true);
        setIsEmailCorrect(false);
        setTimeout(() => {
          setIsEmailError(false);
        }, 10000);
      }
    }
  }

  const [isDesktop, setDesktop] = useState(window.innerWidth > 1199);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 1199);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, [isDesktop]);

  // starting loop of pills and requests
  useEffect(() => {
    setTimeout(() => setDoPillsAppear(true), 8000)
  }, []);

  // construction of the linear strokes
  useEffect(() => {
    let arrayDash = []
    if (isDesktop) {
      for ( let i = 0 ; i <= 83 ; i++) {
        arrayDash = [...arrayDash, <Dash 
          key={i} 
          index={i} 
          initial={10} 
          left={`calc(${(i * 0.5) + 4.5}% + 157px)`}
        />]
      } 
    } else {
      for ( let i = 0 ; i <= 83 ; i++) {
        if (i < 52 || i > 64) {
          arrayDash = [...arrayDash, <Dash 
            key={i} 
            index={i} 
            initial={10} 
            vertical="true"
            top={`calc(${(i * 1.5) + 4.5}% + 100px)`}
          />]
        }
      } 
    }
    setStraightDash(arrayDash)
  }, [isDesktop])

  // construction of the bifurcation interim strokes
  // if you want to make a curve, use parabola calculation
  useEffect(() => {
    let arrayDash = []
    if (isDesktop) {
      for ( let i = 0 ; i <= 24 ; i++) {
        arrayDash = [...arrayDash, <Dash 
          key={i} 
          index={i} 
          initial={60} 
          top={`calc(${50 - 1 * i}%)`}
          left={`calc(${51 + 0.6 * i}% + 149px)`}
          rotate="-35deg"
        />]
      } 
    } else {
      for ( let i = 0 ; i <= 24 ; i++) {
        arrayDash = [...arrayDash, <Dash 
          key={i} 
          index={i} 
          initial={60} 
          top={`calc(${50 + 0.3 * i}% + 670px)`}
          left={`calc(${51 + 0.8 * i}%)`}
          rotate="18deg"
        />]
      } 
    }
    setBifurcationInterimDash(arrayDash)
  }, [isDesktop])

  // construction of the bifurcation recruteurs strokes
  // if you want to make a curve, use parabola calculation
  useEffect(() => {
    let arrayDash = []
    if (isDesktop) {
      for ( let i = 0 ; i <= 35 ; i++) {
        arrayDash = [...arrayDash, <Dash 
          key={i} 
          index={i} 
          initial={60} 
          top={`calc(${50.5 - 0.7 * i}%)`}
          left={`calc(${51 + 0.7 * i}% + 149px)`}
          rotate="-25deg"
        />]
      } 
    } else {
      for ( let i = 0 ; i <= 28 ; i++) {
        arrayDash = [...arrayDash, <Dash 
          key={i} 
          index={i} 
          initial={60} 
          top={`calc(${50 + 0.8 * i}% + 670px)`}
          left={`calc(${51 + 0.7 * i}% - 10px)`}
          rotate="35deg"
        />]
      } 
    }
    setBifurcationRecruteursDash(arrayDash)
  }, [isDesktop])

  // construction of the bifurcation prestas strokes
  // if you want to make a curve, use parabola calculation
  useEffect(() => {
    let arrayDash = []
    if (isDesktop) {
      for ( let i = 0 ; i <= 38 ; i++) {
        arrayDash = [...arrayDash, <Dash 
          key={i} 
          index={i} 
          initial={60} 
          top={`calc(${51 - 0.3 * i}%)`}
          left={`calc(${51 + 0.7 * i}% + 149px)`}
          rotate="-15deg"
        />]
      } 
    } else {
      for ( let i = 0 ; i <= 33 ; i++) {
        arrayDash = [...arrayDash, <Dash 
          key={i} 
          index={i} 
          initial={60} 
          top={`calc(${50 + 1.2 * i}% + 670px)`}
          left={`calc(${51 + 0.6 * i}% - 15px)`}
          rotate="45deg"
        />]
      } 
    }
    setBifurcationPrestasDash(arrayDash)
  }, [isDesktop])

  // construction of the bifurcation joboards strokes
  // if you want to make a curve, use parabola calculation
  useEffect(() => {
    let arrayDash = []
    if (isDesktop) {
      for ( let i = 0 ; i <= 37 ; i++) {
        arrayDash = [...arrayDash, <Dash 
          key={i} 
          index={i} 
          initial={60} 
          top={`calc(${51.5 + 0.2 * i}%)`}
          left={`calc(${51 + 0.7 * i}% + 149px)`}
          rotate="10deg"
        />]
      }
    } else {
      for ( let i = 0 ; i <= 24 ; i++) {
        arrayDash = [...arrayDash, <Dash 
          key={i} 
          index={i} 
          initial={60} 
          top={`calc(${50 + 0.3 * i}% + 670px)`}
          left={`calc(${51 - 0.8 * i}% - 25px)`}
          rotate="-18deg"
        />]
      } 
    }
    setBifurcationJoboardsDash(arrayDash)
  }, [isDesktop])

  // construction of the bifurcation freelances strokes
  // if you want to make a curve, use parabola calculation
  useEffect(() => {
    let arrayDash = []
    if (isDesktop) {
      for ( let i = 0 ; i <= 37 ; i++) {
        arrayDash = [...arrayDash, <Dash 
          key={i} 
          index={i} 
          initial={60} 
          top={`calc(${52 + 0.5 * i}%)`}
          left={`calc(${51 + 0.7 * i}% + 149px)`}
          rotate="15deg"
        />]
      } 
    } else {
      for ( let i = 0 ; i <= 28 ; i++) {
        arrayDash = [...arrayDash, <Dash 
          key={i} 
          index={i} 
          initial={60} 
          top={`calc(${50 + 0.8 * i}% + 670px)`}
          left={`calc(${51 - 0.7 * i}% - 12px)`}
          rotate="-35deg"
        />]
      } 
    }
    setBifurcationFreelancesDash(arrayDash)
  }, [isDesktop])

  // construction of the bifurcation assos strokes
  // if you want to make a curve, use parabola calculation
  useEffect(() => {
    let arrayDash = []
    if (isDesktop) {
      for ( let i = 0 ; i <= 25 ; i++) {
        arrayDash = [...arrayDash, <Dash 
          key={i} 
          index={i} 
          initial={60} 
          top={`calc(${52.5 + 0.9 * i}%)`}
          left={`calc(${51 + 0.7 * i}% + 149px)`}
          rotate="25deg"
        />]
      } 
    } else {
      for ( let i = 0 ; i <= 33 ; i++) {
        arrayDash = [...arrayDash, <Dash 
          key={i} 
          index={i} 
          initial={60} 
          top={`calc(${50 + 1.2 * i}% + 670px)`}
          left={`calc(${51 - 0.6 * i}% - 10px)`}
          rotate="-48deg"
        />]
      } 
    }
    setBifurcationAssosDash(arrayDash)
  }, [isDesktop])


  return (
    <style.ContainerAnimation 
      container 
      height={{xs: "700px", lg: "585px"}}
      {...props}
    >
      {/* ornament ball */}
      {/* <style.Ball src={HomeBallSection1}
        sx={{
          width: {
            xs: "134px", 
            lg: "218px"
          },
          top: {
            xs: "-50px",
            lg: "-70px"
          },
        }}
      /> */}

      {/* logo */}
      <style.ContainerYllone sx={{zIndex: 2}}>
        <style.Yllona
          sx={{
            top: {
              xs: "calc(48% - 42.5px)",
              lg: "calc(50% - 105px)"
            }
          }}
        >
          <Gradient dir="left-to-right" from="#FF30A0" to="#FA194E">
              Conexte
          </Gradient>
        </style.Yllona>
      </style.ContainerYllone>

      {/* user type cards */}
      <Box>
        <style.CardLeft
          sx={{
            // width: {xs: "150px", lg: "345px"},
            top: {xs: "-50px", lg: "calc(50% + 10px)"},
            left: {xs: "50%", lg: "5%"},
            transform: {xs: "translateX(-50%)" ,lg: "translateY(-50%)"},
          }}
        >
          <style.CardLeftImageContainer>
            <style.CardLeftImage  src={CardSection1IngenieurLogiciel}/>
            <Grid container flexDirection="column">
              <style.CardLeftText>Votre entreprise</style.CardLeftText>
              <style.CardDescription>10 postes ouverts</style.CardDescription>
            </Grid>
          </style.CardLeftImageContainer>
          <style.CardLeftName>Ingénieur logiciel</style.CardLeftName>
          <style.CardLeftDetails>
            <style.CardDetailsImage src={CardSection1IngenieurDetails}/>
          </style.CardLeftDetails>
        </style.CardLeft>
        <style.CardTalent  src={TalentsAssos} 
          sx={{
            top: {
              xs: "calc(100% + 550px)",
              lg: "76%"
            }, 
            right: {
              lg: "16%",
              xl:"18%"
            },
            left: {
              xs: "18%",
              lg: "auto"
            }
          }}
        > 
          <style.NameTalent>Associations</style.NameTalent>
          <style.DescriptionTalent>Talents disponibles</style.DescriptionTalent>
          <Grid container flexDirection="row" alignItems="center">
            <style.ImageCardTalent src={TalentsAssos}/>
            <style.NumCard>+200</style.NumCard>
          </Grid>
        </style.CardTalent>
        <style.CardTalent
          sx={{
            top: {
              xs: "calc(100% + 435px)",
              lg: "66%"
            }, 
            right: {
              lg: "5%",
              xl: "8%"
            },
            left: {
              xs: "18%",
              lg: "auto"
            }
          }}
        >
          <style.NameTalent>Freelances</style.NameTalent>
          <style.DescriptionTalent>Talents disponibles</style.DescriptionTalent>
          <Grid container flexDirection="row" alignItems="center">
            <style.ImageCardTalent src={TalentsFreelances}/>
            <style.NumCard>+300</style.NumCard>
          </Grid>
        </style.CardTalent>
        <style.CardTalent  src={TalentsInterim} 
          sx={{
            top: {
              xs: "calc(100% + 320px)",
              lg: "11%"
            }, 
            right: {
              xs: "18%",
              lg: "18%",
              xl: "21%"
            }
          }}
        >
          <style.NameTalent>Interim</style.NameTalent>
          <style.DescriptionTalent>Talents disponibles</style.DescriptionTalent>
          <Grid container flexDirection="row" alignItems="center">
            <style.ImageCardTalent src={TalentsInterim}/>
            <style.NumCard>+1000</style.NumCard>
          </Grid>
        </style.CardTalent>
        <style.CardTalent  src={TalentsJoboards} 
          sx={{
            top: {
              xs: "calc(100% + 320px)",
              lg: "50%"
            }, 
            right: {
              lg: "2%",
              xl: "6%"
            },
            left: {
              xs: "18%",
              lg: "auto"
            }
          }}
        >
          <style.NameTalent>Joboards</style.NameTalent>
          <style.DescriptionTalent>Talents disponibles</style.DescriptionTalent>
          <Grid container flexDirection="row" alignItems="center">
            <style.ImageCardTalent src={TalentsJoboards}/>
            <style.NumCard>+10k</style.NumCard>
          </Grid>
        </style.CardTalent>
        <style.CardTalent  src={TalentsPrestas} 
          sx={{
            top: {
              xs: "calc(100% + 550px)",
              lg: "32%"
            }, 
            right: {
              xs: "18%",
              lg: "2.5%",
              xl: "5%"
            }
          }}
        >
          <style.NameTalent>Sous traitants</style.NameTalent>
          <style.DescriptionTalent>Talents disponibles</style.DescriptionTalent>
          <Grid container flexDirection="row" alignItems="center">
            <style.ImageCardTalent src={TalentsPrestas}/>
            <style.NumCard>+3000</style.NumCard>
          </Grid>
        </style.CardTalent>
        <style.CardTalent 
          sx={{
            top: {
              xs: "calc(100% + 435px)",
              lg: "14%"
            }, 
            right: {
              xs: "18%",
              lg: "5%",
              xl:"10%"
            }
          }}
        >
          <style.NameTalent>Recruteurs</style.NameTalent>
          <style.DescriptionTalent>Talents disponibles</style.DescriptionTalent>
          <Grid container flexDirection="row" alignItems="center">
            <style.ImageCardTalent src={TalentsRecruteurs}/>
            <style.NumCard>+400</style.NumCard>
          </Grid>
        </style.CardTalent>
        <style.RedLine 
          width={{xs: "15px", lg: "82px"}}
          height={{xs: "82px", lg: "15px"}}
          sx={{
            top: {xs: "calc(100% + 240px)", lg: "50%"},
            transform: {xs: "translateX(-50%)", lg: "translateX(100%)"}
          }}
        />
      </Box>

      {/* container with buttons and texts */}
      <style.ContainerButtons container sx={{zIndex: 2}} mt={2}>
        <font.P20 color="#131316" sx={{marginTop: "20px"}} fontSize="40px" fontWeight="500">
          Bientôt disponible
        </font.P20>
        <style.WrapperButtons container>
          <Grid position="relative">
            <Box mt={1} mb={1}>
              <style.InputCustom 
                placeholder="Votre email"
                maxLength="150"
                name="email"
                onChange={(e) => {
                  setIsEmailError(false)
                  setEmail(e.target.value)
                }}
                validators={["required", "isEmail"]}
                variant="outlined" 
                sx={
                  (isEmailSend && {
                    "& .MuiOutlinedInput-root fieldset": {
                      border: "1px solid " + palette.green.light + " !important"
                    }
                  }) 
                  ||
                  (isEmailError && {
                    "& .MuiOutlinedInput-root fieldset": {
                      border: "1px solid " + palette.red.warning + " !important"
                    }
                  }) 
                  || 
                  (isEmailCorrect && {
                    "& .MuiOutlinedInput-root fieldset": {
                      border: "1px solid #862165 !important"
                    }
                  })
                }
              />
            </Box>
            {isEmailSend && (
              <Box position="absolute" left="0" top="calc(100% +20px)">
                <font.P12 color={palette.green.light}>Envoyé</font.P12>
              </Box>
            )}
            {isEmailError && (
              <Box position="absolute" left="0" top="calc(100% - 10px)">
                <font.P12 color={palette.red.main}>{textError}</font.P12>
              </Box>
            )}
          </Grid>
          <Box mt={1} mb={1}>
            <button.PrimaryButton 
              dye="red" 
              size="default" 
              sx={{
                width: "fit-content", 
                padding: "15px 12px",

                ["@media (max-width: 1260px)"]: {
                  marginRight: 0, 
                },
              }}
              onClick={() => inscription()}
            >
              <font.P20 color={palette.white.pattern} fontWeight="700" sx={{whiteSpace: "nowrap"}}>
                Me pré inscrire
              </font.P20>
            </button.PrimaryButton>
          </Box>
        </style.WrapperButtons>
      </style.ContainerButtons>

      {/* stroke rendering */}
      <Box>
        {straightDash.map(item => item)}
        {bifurcationInterimDash.map(item => item)}
        {bifurcationRecruteursDash.map(item => item)}
        {bifurcationPrestasDash.map(item => item)}
        {bifurcationJoboardsDash.map(item => item)}
        {bifurcationFreelancesDash.map(item => item)}
        {bifurcationAssosDash.map(item => item)}
      </Box>

      {/* rendering of the pills */}
      {doPillsAppear && (
        <Box>
          <Box display={{xs: "none", lg: "flex"}}>
            <NeedPill animationPill={style.AnimationHorizontalNeedPill}/>
            <ResponsePill animationPill={style.AnimationHorizontalResponsePill}/>

            <NeedPill animationPill={style.AnimationInterimNeedPill}/>
            <ResponsePill animationPill={style.AnimationInterimResponsePill}/>

            <NeedPill animationPill={style.AnimationRecruteursNeedPill}/>
            <ResponsePill animationPill={style.AnimationRecruteursResponsePill}/>

            <NeedPill animationPill={style.AnimationPrestasNeedPill}/>
            <ResponsePill animationPill={style.AnimationPrestasResponsePill}/>

            <NeedPill animationPill={style.AnimationJoboardNeedPill}/>
            <ResponsePill animationPill={style.AnimationJoboardResponsePill}/>

            <NeedPill animationPill={style.AnimationFreelancesNeedPill}/>
            <ResponsePill animationPill={style.AnimationFreelancesResponsePill}/>

            <NeedPill animationPill={style.AnimationAssosNeedPill}/>
            <ResponsePill animationPill={style.AnimationAssosResponsePill}/>
          </Box>
          <Box display={{xs: "flex", lg: "none"}}>
            <NeedPill animationPill={style.AnimationHorizontalNeedPillTablet}/>
            <ResponsePill animationPill={style.AnimationHorizontalResponsePillTablet}/>

            <NeedPill animationPill={style.AnimationInterimNeedPillTablet}/>
            <ResponsePill animationPill={style.AnimationInterimResponsePillTablet}/>

            <NeedPill animationPill={style.AnimationRecruteursNeedPillTablet}/>
            <ResponsePill animationPill={style.AnimationRecruteursResponsePillTablet}/>

            <NeedPill animationPill={style.AnimationPrestasNeedPillTablet}/>
            <ResponsePill animationPill={style.AnimationPrestasResponsePillTablet}/>

            <NeedPill animationPill={style.AnimationJoboardNeedPillTablet}/>
            <ResponsePill animationPill={style.AnimationJoboardResponsePillTablet}/>

            <NeedPill animationPill={style.AnimationFreelancesNeedPillTablet}/>
            <ResponsePill animationPill={style.AnimationFreelancesResponsePillTablet}/>

            <NeedPill animationPill={style.AnimationAssosNeedPillTablet}/>
            <ResponsePill animationPill={style.AnimationAssosResponsePillTablet}/>
          </Box>
        </Box>
      )}
    </style.ContainerAnimation>
  )
}