import { Box, keyframes } from "@mui/material";
import React from "react";

export const Dash = (props) => {

  const distance = props.index * 0.5 + 4.5;

  const animationDash = keyframes`
    0% {
      opacity: 0
    }
    ${props.initial + 0.5 * props.index}% {
      opacity: 0
    }
    ${props.initial + 0.5 + 0.5 * props.index}% {
      opacity: 1
    }
  `

  return (
    <Box
      position="absolute"
      sx={{
        top: props.top ? props.top : "calc(50% + 7px)",
        left: props.left ? props.left : "50%",
        width: props.vertical ? "1px" : "5px",
        height: props.vertical ? "5px" : "1px",
        transform: `rotate(${props.rotate ? props.rotate : 0})`,
        backgroundColor: "#AFAFAF",
        animation: animationDash + " 10s linear",
        zIndex: "-1"
      }}
    />
  )
}