import { Button, Grid, MenuList } from "@mui/material";
import { styled } from "@mui/system";
import boxShadow from "../../../theme/box-shadow";
import palette from "../../../theme/palette";

export const HeaderCustom = styled(Grid)({
  height: "fit-content",
  justifyContent: "center",
  // position: "fixed",
  position: "absolute",
  zIndex: 9999,
  borderRadius: "0 0 20px 20px",
  maxWidth: "100vw",
  overflow: "hidden",

  "&.white": {
    backgroundColor: palette.white.pattern,
    boxShadow: boxShadow.popup.main_1,
    backdropFilter: "blur(20px)",

    "& > div": {
      padding:"21px 16px"
    }
  },

  "&.blue": {
    backgroundImage: "linear-gradient(180deg, #011E2A -39.28%, #0E4267 191.07%)",
    boxShadow: boxShadow.popup.main_1,
    backdropFilter: "blur(20px)",

    "& > div": {
      padding:"21px 16px"
    },
    
    "& li": {
      color: palette.white.light,

      "&:hover": {
        borderBottom: "5px solid #58D6B9"
      },

      "&.active": {
        color: palette.green.light
      }
    }
  }
})
export const ContainerMenu = styled(Grid)({
  position: "relative",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
})

export const MenuListCustom = styled(MenuList)({
  flexDirection: "row",
  display: "flex",

  "& li": {
    borderTop: "5px solid " + "transparent",
    borderBottom: "5px solid " + "transparent",
    padding: 0,
    margin: "0 19px",
    fontWeight: 900
  },

  "& li:hover": {
    backgroundColor: "transparent",
    borderBottom: "5px solid " + palette.blue.dark
  },

  "& li.active": {
    color: palette.blue.light
  }
})

export const LogoImage = styled("img")({
  width: "100%",
})

export const ButtonLogo = styled(Button)({
  padding: 0,
  marginRight: "10px",
  backgroundColor: "transparent",
  borderRadius: "50%",
  zIndex: 3,
  cursor: "unset",

  "&:hover": {
    backgroundColor: "transparent"
  }
})