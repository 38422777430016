import React from "react";
import { Box } from "@mui/material";
import * as font from "../../../theme/typography";
import palette from "../../../theme/palette";

export const NeedPill = (props) => {

  return (
    <>
      {props?.small ? (
        <Box
          position="absolute"
          sx={{
            opacity: 0,
            transform: `rotate(${props.rotate ? props.rotate : 0})`,
            padding: "1px 8px",
            borderRadius: "10px",
            backgroundColor: "rgba(248, 55, 100, 0.28)",
            animation: props.animationPill + " 20s infinite" 
          }}
        >
          <font.P10 color={palette.red.light}>Besoin</font.P10>
        </Box>
      ) : (
        <Box
          position="absolute"
          sx={{
            opacity: 0,
            transform: `rotate(${props.rotate ? props.rotate : 0}) translateY(-10px)`,
            padding: "1px 20px",
            borderRadius: "10px",
            backgroundColor: "rgba(248, 55, 100, 0.28)",
            animation: props.animationPill + " 20s infinite" 
          }}
        >
          <font.ButtonMedium color={palette.red.light}>Besoin</font.ButtonMedium>
        </Box>
      )}
    </>
  )
}