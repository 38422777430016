import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";

const channelsSlice = createSlice({
  name: "channels",
  initialState,
  reducers: {
    channelsPending: (state) => {
      state.isLoading = true;
      state.error = "";
    },
    channelsSuccess: (state, actions) => {
      state.isLoading = false;
      state.error = "";
      state.channels = actions.payload;
    },
    channelsFailure: (state) => {
      state.isLoading = false;
      state.error = actions.payload;
    },
    userChannelsSuccess: (state, actions) => {
      state.userChannels = actions.payload;
    },
    channelsReset: (state) => {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.channels = initialState.channels;
      state.selectedChannel = initialState.selectedChannel;
      state.selectedUserChannel = initialState.selectedUserChannel;
      state.userChannels = initialState.userChannels;
    },
    selectedChannel: (state, actions) => {
      state.selectedChannel = actions.payload;
    },
    selectedChannelUsers: (state, actions) => {
      state.selectedChannelUsers = actions.payload;
    },
    selectedUserChannel: (state, actions) => {
      state.selectedUserChannel = actions.payload;
    },

  },
});

const { reducer, actions } = channelsSlice;

export const {
  channelsSuccess,
  channelsFailure,
  channelsPending,
  channelsReset,
  selectedChannel,
  selectedChannelUsers,
  selectedUserChannel,
  userChannelsSuccess,
} = actions;


export const channelsActions = {
  channelsSuccess,
  channelsFailure,
  channelsPending,
  channelsReset,
  selectedChannel,
  selectedChannelUsers,
  selectedUserChannel,
  userChannelsSuccess,
}

export default reducer;
