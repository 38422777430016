import { Box, Grid, IconButton, ListItem, ListItemButton } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { styled } from '@mui/material/styles';
import palette from "../../../theme/palette";

export const Background = styled(Box)({
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(70, 70, 70, 0.46)",
  zIndex: "999"
});

export const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: `-100vw`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
    ["@media (min-width: 899px)"]: {
      display: 'none'
    },
  })
);

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const CustomDrawer = styled(Drawer)({
  width: "100vw",
  flexShrink: 0,
  overflow: "hidden",
  "& .MuiDrawer-paper": {
    width: "100vw",
    // backgroundColor: palette.surface_gray.main,
    boxSizing: "border-box",
  },
});

export const ContainerMobileSidebar = styled(Grid)({
  display: "flex",
  position: "absolute",
  top: '0px',
  right: '0px', 
  height: '62px',
});

export const IconButtonCustom = styled(IconButton)({
  position: "absolute",
  right: 0,
  top: "12px",
  right: "12px",
})

export const ListItemButtonCustom = styled(ListItemButton)({
  display: "flex", 
  flexDirection:"column", 
  alignItems: "flex-start", 
  padding: "9px 20px"
})

export const ListItemCustom = styled(ListItem)({
  display: "flex", 
  flexDirection: "column", 
  alignItems: "flex-start", 
  padding: "9px 20px"
})