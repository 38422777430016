import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";

const groupChatsSlice = createSlice({
  name: "groupChats",
  initialState,
  reducers: {
    groupChatsPending: (state) => {
      state.isLoading = true;
      state.error = "";
    },
    groupChatsSuccess: (state, actions) => {
      state.isLoading = false;
      state.error = "";
      state.groupChats = actions.payload;
    },
    groupChatsFailure: (state) => {
      state.isLoading = false;
      state.error = actions.payload;
    },
    groupChatsReset: (state) => {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.groupChats = initialState.groupChats;
    }
  },
});

const { reducer, actions } = groupChatsSlice;

export const {
  groupChatsSuccess,
  groupChatsFailure,
  groupChatsPending,
  groupChatsReset
} = actions;


export const groupChatsActions = {
  groupChatsSuccess,
  groupChatsFailure,
  groupChatsPending,
  groupChatsReset
}

export default reducer;
