import { styled } from "@mui/system";
import { Button, Typography } from "@mui/material";
import palette from "../../../theme/palette";

export const LineButtonCustom = styled(Button)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textTransform: "none",
  borderRadius: "8px",
  width: "121px",
  height: "37px",
  backgroundColor: 'transparent',
  border: "1px solid " + palette.blue.light,
  color: palette.blue.light,

  "&&:hover": {
    backgroundColor: palette.white.dark,
  },

  "&&:disabled": {
    backgroundColor: palette.white.dark,
  },
});