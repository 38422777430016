import React, { useState } from "react";
import { Box } from "@mui/material";

import * as style from "./styles";
import * as font from "../../../theme/typography";
import { CloseFaqIcon, OpenFaqIcon } from "../../../assets";
export const AccordionFaq = (props) => {
  const [openIcon, setOpenIcon] = useState(true);
  const changeOpenIcon = () => setOpenIcon(!openIcon);

  return (
    <style.AccordionStyle>
      <style.ButtonAccordion onClick={changeOpenIcon} >
        <style.AccordionSummaryStyle 
          expandIcon={
            <img 
              src={
                openIcon && OpenFaqIcon 
                || CloseFaqIcon
              } 
              width="20px" 
              maxWidth="20px"
              height="20px"
            />
          }
          pt={0}
          pr={2.5}
          pb={0}
          pl={2.5}
        >
          <font.P20 fontSize={{xs: "18px", lg: "20px"}}>{props.title}</font.P20>
        </style.AccordionSummaryStyle>
      </style.ButtonAccordion>
      <style.AccordionDetailsStyle>
        <Box mb={2}>
          <font.P16 color="#494A4D">
            {props.text}
          </font.P16>
        </Box>
      </style.AccordionDetailsStyle>
    </style.AccordionStyle>
  );
};

