import { combineReducers } from 'redux';
import authReducer from './auth';
import baseReducer from './base';
import chatServerReducer from './chat-server';
import channelReducer from "./channels";
import postReducer from './post';
import loginReducer from './login'
import signupReducer from './signup';
import userReducer from './user';
import groupChatsReducer from './group-chats';

export default combineReducers({
    auth: authReducer,
    base: baseReducer,
    chatServer: chatServerReducer,
    channels: channelReducer,
    post: postReducer,
    login: loginReducer,
    signup: signupReducer,
    user: userReducer,
    groupChats: groupChatsReducer
})

