import React from "react";
import { Routes, Route } from "react-router-dom";
import { AuthRoute } from "./PrivateRoute";

import * as page from "./pages";

function AppRoutes() {
  return (
    <Routes>
      <Route element={<AuthRoute />}>
        <Route path="/" element={<page.ComingSoon />} />
      </Route>
    </Routes>
  );
}
export default AppRoutes;
