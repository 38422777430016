import { styled } from "@mui/system";
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@mui/material";
import palette from "../../../theme/palette";

export const AccordionStyle = styled(Accordion)({
  backgroundColor: "transparent",
  border: "none",
  boxShadow: "none !important",
  borderRadius: "0px !important",
  width: "100%",
  borderBottom: "1px solid #DFDEE2",
  padding: 0,

  "&.MuiPaper-root": {
    marginBottom: 0
  },

  "&.Mui-expanded ": {
    marginTop: 0
  }
});

export const AccordionSummaryStyle = styled(AccordionSummary)({
  width: "100%", 
  textAlign: "left",
  padding: "15px 0",
  minHeight: "58px !important",
  
  ".MuiAccordionSummary-content": {
    margin: "0",
  },

  ["@media (max-width: 1199px)"]: {
  minHeight: "48px !important",
  },
});

export const ButtonAccordion = styled(Button)({
  width: "100%", 
  display: "flex", 
  flexDirection: "row", 
  justifyContent: "space-between", 
  alignItems: "center", 
  textTransform: "inherit",
  padding: "0",

  "&:hover": {
    backgroundColor: "transparent"
  },

  ["@media (max-width: 1199px)"]: {
    padding: "5px 0 !important",
  },
});

export const AccordionDetailsStyle = styled(AccordionDetails)({
  padding: 0,
  paddingBottom: "30px",
  borderBottom: "1px solid #131316"
})