import axios from "axios";
import { gateway } from "../config.js";

export function create(body) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${gateway.url}/newsletters`, body, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    create,
};