import { Box, Grid, Typography } from "@mui/material";
import { styled, keyframes } from "@mui/system";
import palette from "../../../../theme/palette";

export const ContainerAnimation = styled(Grid)({
  width: "100vw",
  maxWidth: "1610px",
  margin: "0 auto",
  justifyContent: "center",
  position: "relative",
  

  ["@media (max-width: 1199px)"]: {  
    marginTop: "70px"
  },
})

export const ContainerYllone = styled(Grid)({
  position: "relative",
  flexDirection: "row",
  width: "100%",
  padding: "0 16px",
  maxWidth: "1290px",
  justifyContent: "space-between"
})

export const Ball = styled("img")({
  position: "absolute",
  left: "0",
})

export const Yllona = styled(Typography)({
  position: "absolute",
  left: "calc(50% - 132.5px)",
  fontWeight: "900",
  fontSize: "70px",
  fontFamily: "Roboto",
  // transform: "translate(-50%, -100%)",
  ["@media (max-width: 1199px)"]: {
    left: "calc(50% - 155.5px)",
  },
})

export const CardLeft = styled(Grid)({
  position: "absolute",
  zIndex: 2,
  width: "300px",
  height: "275px",
  backgroundColor: palette.white.light,
  border: "0.5px solid #D9D9D9",
  boxShadow: "0px 8px 24px -4px rgba(27, 46, 94, 0.08)",
  borderRadius: "10px",
  padding: "27px 10px 27px 27px",

});

export const CardLeftImageContainer = styled(Grid)({
  flexDirection: "row",
  display: "flex",
  alignItems: "center"
})

export const CardLeftImage = styled("img")({
  width: "65px",
  marginRight: "12px",
});

export const CardLeftText = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "24px",
  color: palette.blue.dark,
});

export const CardDescription = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "24px",
  color: palette.black.main_4,
  marginTop: "4px"
});

export const CardLeftName = styled(Typography)({
  background: "linear-gradient(109deg, rgba(242, 114, 144, 0.9) 4.78%, rgba(248, 47, 98, 0.9) 74.77%, rgba(245, 83, 123, 0.9) 100%)",
  boxShadow: "0px 8px 24px -4px rgba(27, 46, 94, 0.07)",
  backdropFilter: "blur(8px)",
  borderRadius: "10px",
  fontFamily: "Roboto",
  fontWeight: 600,
  fontSize: "27px",
  textAlign: "center",
  width: "calc(100% + 20px)",
  lineHeight: "32px",
  color: palette.white.snow, 
  padding: "12px",
  marginTop: "15px"
});

export const CardLeftDetails = styled(Grid)({
  width: "100%",
  backgroundColor: palette.white.pattern,
  boxShadow: "0px 8px 24px -4px rgba(27, 46, 94, 0.08)",
  borderRadius: "10px",
  marginTop: "20px",
  padding: "16px 12px 8px 27px"
});

export const CardDetailsImage = styled("img")({
  width: "100%"
})

export const RedLine = styled(Box)({
  borderRadius: "20px",
  backgroundColor: palette.red.light,
  position: "absolute",
  left: "50%",
  transform: "translateX(100%)",
  zIndex: 2
});

export const CardTalent = styled(Grid)({
  width: "135px",
  position: "absolute",
  zIndex: 2,
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  border: "0.5px solid #D9D9D9",
  boxShadow: "0px 8px 24px -4px rgba(27, 46, 94, 0.08)",
  borderRadius: "10px",
  padding: "8px 4px 4px 8px"
});

export const NameTalent = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: "normal",
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "1",
  color: palette.white.snow,
  backgroundColor: palette.green.main,
  borderRadius: "8px",
  backdropFilter: "blur(8px)",
  boxShadow: "0px 8px 24px -4px rgba(27, 46, 94, 0.07)",
  padding: "4px 10px",
  marginBottom: "8px",
  width: "fit-content"
});

export const DescriptionTalent = styled(Typography)({
  fontFamily: "Poppins",
  color: palette.blue.dark,
  fontWeight: 400,
  fontSize: "11px",
  lineHeight: 1,
  marginBottom: "6px"
})

export const ImageCardTalent = styled("img")({
  height: "25px"
});

export const NumCard = styled(Typography)({
  fontFamily: "Poppins",
  color: palette.blue.dark,
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: 1,
  marginLeft: "5px"
})

export const CardRight = styled("img")({
  width: "130px",
  position: "absolute",
  zIndex: 2
})

export const ContainerButtons = styled(Grid)({
  maxWidth: "fit-content",
  width: '100%',
  flexDirection: "column",
  position: "absolute",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  bottom: "-20px",
  left: "50%",
  transform: "translateX(-50%)",
  padding: "0px 16px",

  "& p:first-of-type": {
    maxWidth: "527px",
  }
})

export const WrapperButtons = styled(Grid)({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  margin: "40px 0 80px 0",

  ["@media (max-width: 1260px)"]: {
    flexDirection: "column",
    margin: "20px 0"
  },

})


export const AnimationHorizontalNeedPill = keyframes({
  "0%": {
    top: "50%",
    left: "calc(5% + 120px)",
    opacity: 0,
  },
  "7%": {
    top: "48%",
    opacity: 1,
  },
  "20%": {
    opacity: 1,
    top: "48%",
  },
  "30%": {
    opacity: 0,
    top: "50%",
    left: "calc(50% + 80px)",
  },
})

export const AnimationHorizontalNeedPillTablet = keyframes({
  "0%": {
    top: "5%",
    left: "46%",
    opacity: 0,
    transform: "rotate(90deg)",
  },
  "7%": {
    left: "48%",
    opacity: 1,
  },
  "20%": {
    opacity: 1,
    left: "48%",
  },
  "30%": {
    opacity: 0,
    left: "46%",
    top: "calc(100% + 250px)",
    transform: "rotate(90deg)",
  },
})

export const AnimationHorizontalResponsePill = keyframes({
  "70%": {
    top: "50%",
    left: "calc(50% + 120px)",
    opacity: 0,
  },
  "77%": {
    top: "51%",
    opacity: 1,
  },
  "90%": {
    opacity: 1,
    top: "51%",
  },
  "100%": {
    opacity: 0,
    top: "50%",
    left: "calc(3% + 120px)",
  },
})

export const AnimationHorizontalResponsePillTablet = keyframes({
  "65%": {
    top: "calc(100% + 250px)",
    left: "45%",
    opacity: 0,
    transform: "rotate(-90deg)",
  },
  "70%": {
    left: "42%",
    opacity: 1,
  },
  "90%": {
    opacity: 1,
    left: "42%",
  },
  "95%": {
    opacity: 0,
    left: "45%",
    top: "7%",
    transform: "rotate(-90deg)",
  },
})

export const AnimationInterimNeedPill = keyframes({
  "30%": {
    top: "45%",
    left: "calc(50% + 120px)",
    opacity: 0,
    transform: "rotate(-35deg)"
  },
  "30.5%": {
    opacity: 1,
  },
  "40%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0,
    top: "20.5%",
    left: "calc(66% + 120px)",
  },
})

export const AnimationInterimNeedPillTablet = keyframes({
  "30%": {
    top: "calc(100% + 280px)",
    right: "48%",
    opacity: 0,
    transform: "rotate(15deg)"
  },
  "30.5%": {
    opacity: 1,
  },
  "40%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0,
    top: "calc(100% + 365px)",
    right: "19%",
  },
})

export const AnimationInterimResponsePill = keyframes({
  "50%": {
    top: "23.5%",
    opacity: 0,
    left: "calc(67% + 120px)",
    transform: "rotate(-32deg)"
  },
  "50.5%": {
    opacity: 1,
  },
  "60%": {
    opacity: 1,
    transform: "rotate(-32deg)"
  },
  "68%": {
    opacity: 0,
    top: "45%",
  },
  "70%": {
    top: "50%",
    left: "calc(50% + 120px)",
  },
})

export const AnimationInterimResponsePillTablet = keyframes({
  "50%": {
    top: "calc(100% + 400px)",
    opacity: 0,
    right: "19%",
    transform: "rotate(15deg)"
  },
  "50.5%": {
    opacity: 1,
  },
  "60%": {
    opacity: 1,
  },
  "68%": {
    opacity: 0,
    right: "46%",
    top: "calc(100% + 300px)",
  },
  "70%": {
    top: "calc(100% + 310px)",
    right: "48%",
    transform: "rotate(15deg)"
  },
})

export const AnimationRecruteursNeedPill = keyframes({
  "30%": {
    top: "50%",
    left: "calc(50% + 120px)",
    opacity: 0,
    transform: "rotate(-25deg)"
  },
  "30.5%": {
    top: "47.5%",
    opacity: 1,
  },
  "40%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0,
    top: "25%",
    left: "calc(74% + 120px)",
  },
})

export const AnimationRecruteursNeedPillTablet = keyframes({
  "30%": {
    top: "calc(100% + 270px)",
    right: "48%",
    opacity: 0,
    transform: "rotate(35deg)"
  },
  "30.5%": {
    opacity: 1,
  },
  "40%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0,
    top: "calc(100% + 460px)",
    right: "24%",
    transform: "rotate(40deg)"
  },
})

export const AnimationRecruteursResponsePill = keyframes({
  "50%": {
    top: "26.5%",
    opacity: 0,
    left: "calc(75% + 120px)",
    transform: "rotate(-20deg)"
  },
  "50.5%": {
    opacity: 1,
  },
  "60%": {
    opacity: 1,
    transform: "rotate(-20deg)"
  },
  "68%": {
    opacity: 0,
    top: "50%",
  },
  "70%": {
    top: "50%",
    left: "calc(50% + 120px)",
  },
})

export const AnimationRecruteursResponsePillTablet = keyframes({
  "50%": {
    top: "calc(100% + 490px)",
    opacity: 0,
    right: "24%",
    transform: "rotate(40deg)"
  },
  "50.5%": {
    opacity: 1,
  },
  "60%": {
    opacity: 1,
  },
  "68%": {
    opacity: 0,
    right: "46%",
    top: "calc(100% + 300px)",
  },
  "70%": {
    top: "calc(100% + 310px)",
    right: "48%",
  },
})

export const AnimationPrestasNeedPill = keyframes({
  "30%": {
    top: "50%",
    left: "calc(50% + 120px)",
    opacity: 0,
    transform: "rotate(-12deg)"
  },
  "30.5%": {
    top: "48%",
    opacity: 1,
  },
  "40%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0,
    top: "36%",
    left: "calc(79% + 120px)",
  },
})

export const AnimationPrestasNeedPillTablet = keyframes({
  "30%": {
    top: "calc(100% + 252px)",
    right: "48%",
    opacity: 0,
    transform: "rotate(50deg)"
  },
  "30.5%": {
    opacity: 1,
  },
  "40%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0,
    top: "calc(100% + 587px)",
    right: "24%",
    transform: "rotate(55deg)"
  },
})

export const AnimationPrestasResponsePill = keyframes({
  "50%": {
    top: "38%",
    opacity: 0,
    left: "calc(80% + 120px)",
    transform: "rotate(-10deg)"
  },
  "50.5%": {
    opacity: 1,
  },
  "60%": {
    opacity: 1,
    transform: "rotate(-10deg)"
  },
  "68%": {
    opacity: 0,
    top: "50%",
  },
  "70%": {
    top: "50%",
    left: "calc(50% + 120px)",
  },
})

export const AnimationPrestasResponsePillTablet = keyframes({
  "50%": {
    top: "calc(100% + 630px)",
    opacity: 0,
    right: "24%",
    transform: "rotate(55deg)"
  },
  "50.5%": {
    opacity: 1,
  },
  "60%": {
    opacity: 1,
  },
  "68%": {
    opacity: 0,
    right: "46%",
    top: "calc(100% + 300px)",
  },
  "70%": {
    top: "calc(100% + 310px)",
    right: "48%",
    transform: "rotate(50deg)"
  },
})

export const AnimationJoboardNeedPill = keyframes({
  "30%": {
    top: "50%",
    left: "calc(50% + 120px)",
    opacity: 0,
    transform: "rotate(8deg)"
  },
  "30.5%": {
    top: "49%",
    opacity: 1,
  },
  "40%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0,
    top: "56%",
    left: "calc(79% + 120px)",
  },
})

export const AnimationJoboardNeedPillTablet = keyframes({
  "30%": {
    top: "calc(100% + 280px)",
    left: "48%",
    opacity: 0,
    transform: "rotate(-15deg)"
  },
  "30.5%": {
    opacity: 1,
  },
  "40%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0,
    top: "calc(100% + 365px)",
    left: "19%",
  },
})

export const AnimationJoboardResponsePill = keyframes({
  "50%": {
    top: "60%",
    opacity: 0,
    left: "calc(80% + 120px)",
    transform: "rotate(5deg)"
  },
  "50.5%": {
    opacity: 1,
  },
  "60%": {
    opacity: 1,
  },
  "65%": {
    opacity: 0,
  },
  "70%": {
    top: "48%",
    left: "calc(48% + 120px)",
  },
})

export const AnimationJoboardResponsePillTablet = keyframes({
  "50%": {
    top: "calc(100% + 400px)",
    opacity: 0,
    left: "19%",
    transform: "rotate(-15deg)"
  },
  "50.5%": {
    opacity: 1,
  },
  "60%": {
    opacity: 1,
  },
  "68%": {
    opacity: 0,
    left: "46%",
    top: "calc(100% + 300px)",
  },
  "70%": {
    top: "calc(100% + 310px)",
    left: "48%",
    transform: "rotate(-15deg)"
  },
})

export const AnimationFreelancesNeedPill = keyframes({
  "30%": {
    top: "50%",
    left: "calc(50% + 120px)",
    opacity: 0,
    transform: "rotate(15deg)"
  },
  "30.5%": {
    top: "49%",
    opacity: 1,
  },
  "40%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0,
    top: "68%",
    left: "calc(79% + 120px)",
  },
})

export const AnimationFreelancesNeedPillTablet = keyframes({
  "30%": {
    top: "calc(100% + 270px)",
    left: "48%",
    opacity: 0,
    transform: "rotate(-35deg)"
  },
  "30.5%": {
    opacity: 1,
  },
  "40%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0,
    top: "calc(100% + 460px)",
    left: "24%",
    transform: "rotate(-40deg)"
  },
})

export const AnimationFreelancesResponsePill = keyframes({
  "50%": {
    top: "74%",
    opacity: 0,
    left: "calc(80% + 120px)",
    transform: "rotate(18deg)"
  },
  "50.5%": {
    opacity: 1,
  },
  "60%": {
    opacity: 1,
  },
  "65%": {
    opacity: 0,
  },
  "70%": {
    top: "48%",
    left: "calc(48% + 120px)",
  },
})

export const AnimationFreelancesResponsePillTablet = keyframes({
  "50%": {
    top: "calc(100% + 490px)",
    opacity: 0,
    left: "24%",
    transform: "rotate(-40deg)"
  },
  "50.5%": {
    opacity: 1,
  },
  "60%": {
    opacity: 1,
  },
  "68%": {
    opacity: 0,
    left: "46%",
    top: "calc(100% + 300px)",
  },
  "70%": {
    top: "calc(100% + 310px)",
    left: "48%",
  },
})

export const AnimationAssosNeedPill = keyframes({
  "30%": {
    top: "50%",
    left: "calc(50% + 120px)",
    opacity: 0,
    transform: "rotate(28deg)"
  },
  "30.5%": {
    top: "48%",
    opacity: 1,
  },
  "40%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0,
    top: "74%",
    left: "calc(69% + 120px)",
  },
})

export const AnimationAssosNeedPillTablet = keyframes({
  "30%": {
    top: "calc(100% + 252px)",
    left: "48%",
    opacity: 0,
    transform: "rotate(-50deg)"
  },
  "30.5%": {
    opacity: 1,
  },
  "40%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0,
    top: "calc(100% + 587px)",
    left: "24%",
    transform: "rotate(-55deg)"
  },
})

export const AnimationAssosResponsePill = keyframes({
  "50%": {
    top: "74%",
    opacity: 0,
    left: "calc(67% + 120px)",
    transform: "rotate(28deg)"
  },
  "50.5%": {
    opacity: 1,
  },
  "60%": {
    opacity: 1,
  },
  "65%": {
    opacity: 0,
  },
  "70%": {
    top: "48%",
    left: "calc(48% + 120px)",
  },
})

export const AnimationAssosResponsePillTablet = keyframes({
  "50%": {
    top: "calc(100% + 630px)",
    opacity: 0,
    left: "24%",
    transform: "rotate(-55deg)"
  },
  "50.5%": {
    opacity: 1,
  },
  "60%": {
    opacity: 1,
  },
  "68%": {
    opacity: 0,
    left: "46%",
    top: "calc(100% + 300px)",
  },
  "70%": {
    top: "calc(100% + 310px)",
    left: "48%",
    transform: "rotate(-50deg)"
  },
})
