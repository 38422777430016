import React, { useState } from "react";
import * as style from "../styles";
import { Accordion, AccordionSummary, Box, Button } from "@mui/material";
import { AccordionFaq } from "../../../components/accordions/accordion-faq";

export const SectionElevenContainer = () => {
  const [alignment, setAlignment] = useState('Toutes');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <style.SectionContainer pt={{xs: "700px", md: 0}}>
      <style.SectionElevenContainer>
        <style.TitleEleven>
          Foire aux questions
        </style.TitleEleven>
        <style.ToggleButtonGroupFaq
          color="primary"
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <style.ToggleButtonFaq value="Toutes">Toutes</style.ToggleButtonFaq>
          <style.ToggleButtonFaq value="Fonctionnement">Fonctionnement</style.ToggleButtonFaq>
          <style.ToggleButtonFaq value="Tarif">Tarif</style.ToggleButtonFaq>
        </style.ToggleButtonGroupFaq>
        <Box maxWidth="824px" mt={{xs: "16px", lg: "30px"}}>
          {(alignment === "Toutes" || alignment === "Fonctionnement") && (
            <AccordionFaq
              title="Comment fonctionne la plateforme Conexte ?"
              text="La plateforme Conexte fonctionne en mettant en relation les entreprises avec des prestataires de services tels que les agences d'intérim, les cabinets de recrutement, les sites d'emploi, les plateformes d'indépendants et les sous-traitants. Vous pouvez déposer vos besoins de recrutement ou de services sur la plateforme et recevoir des propositions de nos partenaires."
            />
          )}
          {(alignment === "Toutes" || alignment === "Tarif") && (
            <AccordionFaq
              title="Y a-t-il des frais pour utiliser la plateforme Conexte ?"
              text="L'utilisation de la plateforme Conexte est gratuite pour les entreprises qui recherchent des prestataires. Les prestataires peuvent être soumis à des frais en fonction de leur niveau d'abonnement ou de leur utilisation de services spécifiques."
            />
          )}
          {(alignment === "Toutes" || alignment === "Fonctionnement") && (
            <AccordionFaq
              title="Quels types de prestataires puis-je trouver sur Conexte ?"
              text="Sur Conexte, vous pouvez trouver une variété de prestataires tels que des agences d'intérim, cabinets de recrutement, sites d'emploi, plateformes d'indépendants, sous-traitants et associations d'aide au retour à l'emploi, couvrant un large éventail de secteurs d'activité."
            />
          )}
          {(alignment === "Toutes" || alignment === "Fonctionnement") && (
            <AccordionFaq
              title="Comment Conexte assure-t-elle la qualité des prestataires partenaires ? "
              text="Conexte assure la qualité des prestataires partenaires en vérifiant leurs informations, en examinant leurs références et en recueillant les avis des clients. Nous encourageons également les entreprises à évaluer les prestataires après chaque collaboration pour maintenir un niveau de qualité élevé."
            />
          )}
          {(alignment === "Toutes" || alignment === "Tarif") && (
            <AccordionFaq
              title="Est-il possible de communiquer directement avec les prestataires via la plateforme ?"
              text="Oui, vous pouvez communiquer directement avec les prestataires via Conexte. Utilisez la messagerie intégrée pour poser des questions, discuter des détails du projet ou du recrutement et échanger des documents."
            />
          )}
          {(alignment === "Toutes" || alignment === "Fonctionnement") && (
            <AccordionFaq
              title="Quels sont les délais pour recevoir des propositions de partenaires ? "
              text="Les délais pour recevoir des propositions de partenaires varient en fonction de la nature de votre demande et du nombre de partenaires disponibles. En général, vous devriez commencer à recevoir des propositions dans les 24 à 48 heures suivant la soumission de votre demande."
            />
          )}
        </Box>
      </style.SectionElevenContainer>
    </style.SectionContainer>
  )
}