import { Typography } from "@mui/material";
import React from "react";
import palette from "../../../palette";

/* 
  Required parameters:
    id: component id
    children: component that the mouse will hover over to show the tooltip
              <Component>What stay here is the "children"</Component>

  Extra parameters:
    color: use 'color' property if you want a different color
*/

export const P16 = (props) => {
  return (
    <Typography
      {...props}
      fontFamily="Poppins"
      fontWeight={props.fontWeight || "400"}
      fontSize={props.fontSize || "16px"}
      lineHeight={props.lineHeight || "24px"}
      color={props.color || palette.green.dark_2}
    >
      {props.children}
    </Typography>
  );
};