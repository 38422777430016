import { styled } from "@mui/system";
import { Button, Typography } from "@mui/material";
import palette from "../../../theme/palette";

export const SecondaryButtonCustom = styled(Button)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textTransform: "none",
  borderRadius: "8px",
  height: "41px",

  "&&.green": {
    backgroundColor: palette.white.rgba68,
    color: palette.green.dark,

    "&&:hover": {
      backgroundColor: palette.green.background,
      color: palette.white.light,
    },

    "&&:disabled": {
      backgroundColor: palette.green.background,
    },
  },

  "&&.blue": {
    backgroundColor: palette.white.rgba68,
    color: palette.blue.light,

    "&&:hover": {
      backgroundColor: palette.blue.background,
      color: palette.white.light,
    },

    "&&:disabled": {
      backgroundColor: palette.blue.background,
    },
  },
});