import { styled } from "@mui/system";
import { Button, Typography } from "@mui/material";
import palette from "../../../theme/palette";

export const RgbButtonCustom = styled(Button)({
  position: "relative",
  border: "2px solid transparent",
  borderRadius: "8px",
  background: palette.white.snow,
  backgroundClip: "padding-box",
  padding: "8px",
  textTransform: "none",
  width: "254px",
  height: "62px",
  color: palette.green.main,

  "&::after": {
    position: "absolute",
    top: "-2px", bottom: "-2px",
    left: "-2px", right: "-2px",
    background: "linear-gradient(90deg, rgba(49,209,171,1) 18%, rgba(248,47,98,1) 100%)",
    content: "''",
    zIndex: "-1",
    borderRadius: "8px",
  },

  "&&:hover": {
    background: palette.white.snow,
    backgroundClip: "padding-box",

    "&::after": {
      background: "linear-gradient(90deg, rgba(248,47,98,1) 18%, rgba(49,209,171,1) 100%)",
    },
  },

  "&&:disabled": {
    background: palette.white.snow,
    backgroundClip: "padding-box",

    "&::after": {
      background: "linear-gradient(90deg, rgba(248,47,98,1) 18%, rgba(49,209,171,1) 100%)",
    },
  },
});