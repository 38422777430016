import { styled } from "@mui/system";
import { Button, Typography } from "@mui/material";
import palette from "../../../theme/palette";

export const TextButtonCustom = styled(Button)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textTransform: "none",
  borderRadius: "8px",
  width: "fit-content",
  height: "fit-content",
  backgroundColor: 'transparent',

  "&&.green": {
    color: palette.green.dark,

    "&&:hover": {
      color: palette.black.main_4,
    },

    "&&:disabled": {
      color: palette.black.main_4,
    },
  },

  "&&.blue": {
    color: palette.blue.light,

    "&&:hover": {
      color: palette.black.main_4,
    },

    "&&:disabled": {
      color: palette.black.main_4,
    },
  },
});

export const fontStyle = styled(Typography)({
  fontWeight: 500,
  fontSize: "19px",
  lineHeight: "22.27px",
});