import { styled } from "@mui/system";
import { Button, Typography } from "@mui/material";
import palette from "../../../theme/palette";

export const ButtonCustom = styled(Button)({
  padding: "8px 38px",
  textAlign: "center",
  backgroundColor: "#3EDCB7",
  borderRadius: "8px",

  "&:hover": {
    backgroundColor: "#3EDCB7"
  },

  "&:disabled": {
    backgroundColor: palette.grey.medium
  }
});