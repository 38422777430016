import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";

const signupSlice = createSlice({
    name: "signup",
    initialState,
    reducers: {
        signupPending: (state) => {
            state.isLoading = true;
            state.error = "";
            state.isSuccess= false
        },
        signupSuccess: (state) => {
            state.isLoading = false;          
            state.error = "";
            state.isSuccess= true
        },
        signupFailure: (state, actions) => {
            state.isLoading = false;
            state.error = actions.payload;
            state.isSuccess= false;
        },
        signupReset: (state) => {
            state.isLoading = initialState.isLoading;
            state.error = initialState.error;
            state.isSuccess = initialState.isSuccess;
        },
    }
});

const { reducer, actions } = signupSlice

export const { 
    signupPending, 
    signupSuccess, 
    signupFailure,
    signupReset 
} = actions;

export const signupActions = {
    signupPending,
    signupSuccess,
    signupFailure,
    signupReset
}

export default reducer;