import React from "react";
import { Box } from "@mui/material";
import * as font from "../../../theme/typography";
import palette from "../../../theme/palette";

export const ResponsePill = (props) => {
  
  return (
    <>
      {props?.small ? (
        <Box
          position="absolute"
          sx={{
            opacity: 0,
            transform: `rotate(${props.rotate ? props.rotate : 0}) translateY(10px)`,
            padding: "1px 8px",
            borderRadius: "10px",
            backgroundColor: "rgba(49, 243, 197, 0.25)",
            animation: props.animationPill + " 20s infinite", 
            zIndex: "1"
          }}
        >
          <font.P10 color={palette.green.main}>Réponse</font.P10>
        </Box>
      ) : (
        <Box
          position="absolute"
          sx={{
            opacity: 0,
            transform: `rotate(${props.rotate ? props.rotate : 0}) translateY(10px)`,
            padding: "1px 20px",
            borderRadius: "10px",
            backgroundColor: "rgba(49, 243, 197, 0.25)",
            animation: props.animationPill + " 20s infinite", 
            zIndex: "1"
          }}
        >
          <font.P16 color={palette.green.main}>Réponse</font.P16>
        </Box>
      )}
    </>
  )
}