import { Box, Grid } from "@mui/material";
import { styled } from "@mui/system";
import boxShadow from "../../../theme/box-shadow";
import palette from "../../../theme/palette";

export const ContainerCard = styled(Grid)({
  backgroundColor: palette.white.pattern,
  // boxShadow: boxShadow.grey.main_5,
  border: "1px solid rgba(218,218,218,0.41)",
  flexDirection: "column",
  flexWrap: "wrap"
})

export const Image = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "contain"
})

export const WrapperText = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "10px",
  height: "100%"
})