import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";

const chatServerSlice = createSlice({
  name: "chatServer",
  initialState,
  reducers: {
    chatServerTokenPending: (state) => {
      state.isLoading = true;
      state.error = "";
    },
    chatServerTokenSuccess: (state, actions) => {
      state.isLoading = false;
      state.error = "";
      state.chatServerToken = actions.payload;
    },
    chatServerTokenFailure: (state) => {
      state.isLoading = false;
      state.error = actions.payload;
    },
    chatServerResponse: (state, actions) => {
      state.response = actions.payload;
    },
    chatServerResponseReset: (state, actions) => {
      state.response = initialState.response;
    },
    chatServerReset: (state) => {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.chatServerToken = initialState.chatServerToken;
    }
  },
});

const { reducer, actions } = chatServerSlice;

export const {
  chatServerTokenSuccess,
  chatServerTokenFailure,
  chatServerTokenPending,
  chatServerResponse,
  chatServerResponseReset,
  chatServerReset
} = actions;


export const chatServerActions = {
  chatServerTokenSuccess,
  chatServerTokenFailure,
  chatServerTokenPending,
  chatServerResponse,
  chatServerResponseReset,
  chatServerReset
}

export default reducer;