import { Accordion, Box, Button, Grid, IconButton, TextField, ToggleButton, ToggleButtonGroup, Typography, keyframes } from "@mui/material";
import { styled } from "@mui/system";
import palette from "../../theme/palette";
import boxShadow from "../../theme/box-shadow";

export const Home = styled(Grid)({
  // display: "flex",
  // flexDirection: "column",
  // alignItems: "center",
  // justifyItems: "center",
  width: "100vw",
  maxWidth: "100vw",
  overflow: "hidden",
  // position: "relative"
});

export const ScrollToTop = styled(IconButton)({
  backgroundColor: "#F03964",
  padding: "10px",
  borderRadius: "50%",
  width: "57px",
  height: "57px",
  position: "fixed",
  bottom: "20px",
  right: "10%",
  zIndex: 5,

  "&:hover": {    
    backgroundColor: "#F03964",
  },

  ["@media (max-width: 1199px)"]: {
    bottom: "20px",
    right: "2%",
  },
})

export const SectionContainer = styled(Grid)({
  width: "100%",
  maxWidth: "1440px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  margin: "32px auto -20px auto",
  flexDirection: "column",

  ["@media (max-width: 1199px)"]: {
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 0,
    paddingTop: "700px"
  },
});

// Section One

export const ColorContainer = styled(Grid)({
  backgroundColor: palette.blue.background_2,
  borderRadius: "54px",
  maxWidth: "1379px",
  width: "calc(100% - 48px)",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  paddingBottom: "115px",
  position: "relative",

  ["@media (max-width: 1199px)"]: {
    margin: "0 auto",
    width: "100%",
    borderRadius: "0px 0px 28px 28px",
    marginBottom: "24px"
  },

  ["@media (max-width: 899px)"]: {
    paddingBottom: "24px",
  },
});

export const Header = styled(Grid)({
  width: "100%",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "32px 65px 0 65px"
});

export const HeaderMobile = styled(Grid)({
  width: "100%",
  maxWidth: "1440px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "32px auto 0 auto",
  flexDirection: "column",

  ["@media (max-width: 1199px)"]: {
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 0
  },
})

export const ContainerLogo = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center"
}) 

export const Logo = styled("img")({
  width: "44px"
});

export const Conexte = styled(Typography)({
  color: palette.white.main_2,
  fontWeight: "600",
  fontFamily: "Poppins",
  fontSize: "28px",
  lineHeight: "32px",
  marginLeft: "10px"
});

export const HeaderOptions = styled(Grid)({
  display: "flex",
  flexDirection: "row"
});

export const HeaderOption = styled(Button)({

});

export const HeaderButton = styled(Button)({
  backgroundColor: palette.white.pattern,
  color: palette.black.main_9,
  borderRadius: "12px",
  padding: "12px 24px",

  "&:hover": {
    backgroundColor: palette.white.pattern,
  }
});

export const ContainerTextHeader = styled(Grid)({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  padding: "32px 65px 0 65px",
  position: "relative",

  ["@media (max-width: 1199px)"]: {
    margin: "0",
    padding: "32px 24px 0 24px"
  },

  ["@media (max-width: 899px)"]: {
    paddingTop: "20px",
    paddingBottom: "20px",
    alignItems: "center",
  },
});

export const Title = styled(Typography)({
  fontSize: "64px",
  fontFamily: "Poppins",
  fontWeight: "500",
  lineHeight: "70.4px",
  letterSpacing: "-2px",
  color: palette.white.pattern,

  ["@media (max-width: 1199px)"]: {
    fontSize: "32px",
    lineHeight: "110%",
    maxWidth: "400px"
  },
});

export const ButtonsContainer = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  
  ["@media (max-width: 1199px)"]: {
    flexDirection: "column",
  },
});

export const ButtonGreen = styled(Button)({
  backgroundColor: palette.green.button,
  borderRadius: "16px",
  padding: "16px 32px",
  maxWidth: "420px",
  zIndex: 3,
  position: "relative",
  

  "&:hover" :{
    backgroundColor: palette.green.button,
  }
});

export const ButtonWhite = styled(Button)({
  borderRadius: "16px",
  padding: "16px 32px",
  backgroundColor: palette.white.main_2,
  marginLeft: "15px",
  maxWidth: "420px",
  zIndex: 3,
  position: "relative",

  "&:hover" :{
    backgroundColor: palette.white.main_2,
  },
  
  ["@media (max-width: 1199px)"]: {
    marginLeft: 0,
    marginTop: "16px",
  },
});

export const ImageButton = styled("img")({
  marginRight: "10px"
})

export const AnimationContainer = styled(Grid)({
  position: "absolute",
  bottom: "54px",
  right: "40px",
  width: "450px",
  height: "470px",
  top: "20px",

  ["@media (max-width: 1199px)"]: {
    right: "24px"
  },

  ["@media (max-width: 899px)"]: {
    right: "auto",
    left: "50%",
    transform: "translateX(-50%)",
    bottom: "auto",
    width: "320px",
  },
});

export const BoxImage1 = styled(Box)({
  position: "absolute",
  top: "160px",
  left: "177px",

  ["@media (max-width: 899px)"]: {
    top: "100px",
    left: "127px",
  }
})
const animationImage1 = keyframes`
  0% {
    opacity: 0
  }
  1.5% {
    opacity: 0
  }
  5% {
    opacity: 1
  }
`
export const Image1 = styled("img")({
  animation: animationImage1 + " 20s linear",

  ["@media (max-width: 899px)"]: {
    width: "42px",
  }
})

export const BoxImage2 = styled(Box)({
  position: "absolute",
  top: "60px",
  left: "100px",

  ["@media (max-width: 899px)"]: {
    top: "45px",
    left: "85px",
  }
})
const animationImage2 = keyframes`
  0% {
    opacity: 0
  }
  11.5% {
    opacity: 0
  }
  15% {
    opacity: 1
  }
`
export const Image2 = styled("img")({
  animation: animationImage2 + " 20s linear",

  ["@media (max-width: 899px)"]: {
    width: "35px",
  }
})

export const BoxImage3 = styled(Box)({
  position: "absolute",
  top: "80px",
  left: "315px",

  ["@media (max-width: 899px)"]: {
    left: "225px",
    top: "40px"
  }
})
export const Image3 = styled("img")({
  animation: animationImage2 + " 20s linear",

  ["@media (max-width: 899px)"]: {
    width: "54px",
  }
})

export const BoxImage4 = styled(Box)({
  position: "absolute",
  top: "230px",
  left: "340px",

  ["@media (max-width: 899px)"]: {
    left: "240px",
    top: "140px"
  }
})
export const Image4 = styled("img")({
  animation: animationImage2 + " 20s linear",

  ["@media (max-width: 899px)"]: {
    width: "53px",
  }
})

export const BoxImage5 = styled(Box)({
  position: "absolute",
  top: "330px",
  left: "160px",

  ["@media (max-width: 899px)"]: {
    left: "136px",
    top: "205px"
  }
})
export const Image5 = styled("img")({
  animation: animationImage2 + " 20s linear",

  ["@media (max-width: 899px)"]: {
    width: "74px",
  }
})

export const BoxImage6 = styled(Box)({
  position: "absolute",
  top: "235px",
  left: "70px",

  ["@media (max-width: 899px)"]: {
    left: "73px",
    top: "160px"
  }
})
export const Image6 = styled("img")({
  animation: animationImage2 + " 20s linear",

  ["@media (max-width: 899px)"]: {
    width: "33px",
  }
})

export const BoxImage7 = styled(Box)({
  position: "absolute",
  top: "0px",
  left: "217px",

  ["@media (max-width: 899px)"]: {
    left: "158px",
    top: "3px"
  }
})
const animationImage7 = keyframes`
0% {
  opacity: 0
}
21.5% {
  opacity: 0
}
25% {
  opacity: 1
}
`
export const Image7 = styled("img")({
  animation: animationImage7 + " 20s linear",

  ["@media (max-width: 899px)"]: {
    width: "33px",
  }
})

export const BoxImage8 = styled(Box)({
  position: "absolute",
  top: "310px",
  left: "20px",

  ["@media (max-width: 899px)"]: {
    left: "56px",
    top: "206px"
  }
})
export const Image8 = styled("img")({
  animation: animationImage7 + " 20s linear",

  ["@media (max-width: 899px)"]: {
    width: "42px",
  }
});

export const BoxImage9 = styled(Box)({
  position: "absolute",
  top: "127px",
  left: "14px",

  ["@media (max-width: 899px)"]: {
    left: "29px",
    top: "102px"
  }
})
export const Image9 = styled("img")({
  animation: animationImage7 + " 20s linear",

  ["@media (max-width: 899px)"]: {
    width: "35px",
  }
});

// Section Two

export const TitleSectionTwo = styled(Typography)({
  color: palette.black.main_10,
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: "36px",
  lineHeight: "120%",
  letterSpacing: "-1px",
  textAlign: "center",
  width: "100%",
  ["@media (max-width: 1199px)"]: {
    fontSize: "24px",
  }
});

export const OtherLogo = styled("img")({
  height: "130px",
  maxWidth: "200px",
  
  ["@media (max-width: 1399px)"]: {
    maxWidth: "121px",
    height: "83px",
  }
});

export const LogosContainer = styled(Grid)({
  maxWidth: "1379px",
  width: "calc(100% - 48px)",
  overflow: "scroll",
  display: "flex",
  justifyContent: "space-between",
  padding: "0 65px",

  "&::-webkit-scrollbar": {
    display: "none",
  },

  "&:hover::-webkit-scrollbar": {
    display: "none",
  },

  ["@media (max-width: 1199px)"]: {
    margin: "0 auto",
    width: "100%",
    padding: "0 24px",
  },
});

// Section Three

export const SectionThreeContainer = styled(Grid)({
  width: "calc(100% - 48px)",
  padding: "0 65px",

  ["@media (max-width: 1199px)"]: {
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    padding: "0 24px",
  },
})

export const TitleThree = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "48px",
  lineHeight: "120%",
  letterSpacing: "-1px",
  color: palette.black.main_8,
  marginTop: "38px",
  width: "100%",
  ["@media (max-width: 1199px)"]: {
    fontSize: "32px",
  }
});

export const PrestationsContainer = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "space-between",
  maxWidth: "1430px",
  
  ["@media (max-width: 1199px)"]: {
    flexWrap: "nowrap",
    overflow: "scroll",
    justifyContent: "flex-start",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  
    "&:hover::-webkit-scrollbar": {
      display: "none",
    },
  },
});

export const Prestation = styled(Grid)({
  width: "380px",
  minWidth: "380px",
  height: "166px",
  boxShadow: "21px 23px 0px 0px rgba(240,240,240,1)",
  marginTop: "50px",
  display: "flex",
  flexDirection: "row",
  borderRadius: "14px",
  backgroundColor: palette.white.main,
  border: "1px solid #0A0F0D",
  overflow: "hidden",
  marginRight: "40px",
  position: "relative",

  "&:nth-of-type(3n)": {
    marginRight: "0"
  },
  
  
  ["@media (max-width: 1398px)"]: {
    marginRight: "10px",
    width: "340px",
    minWidth: "340px",
    height: "165px",

    "&:nth-chield(3 + n)": {
      marginRight: "0px"
    },
    "&:last-of-type":{
      marginRight: "0"
    }
  },

  ["@media (max-width: 1260px)"]: {
    width: "320px",
    minWidth: "320px",
  },
  
  ["@media (max-width: 1139px)"]: {
    "&:nth-chield(3 + n)": {
      marginRight: "40px"
    },
    "&:last-of-type":{
      marginRight: "0"
    }
  },
});

export const PrestationImage = styled("img")({
  width: "102%",
  height: "106%",
  objectFit: "cover",
  borderRadius: "14px 0 0 14px",
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  
  ["@media (max-width: 1398px)"]: {
    height: "225px",
  },
});

export const PrestationTextContainer = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  padding: "14px 12px"
});

export const PrestationTitle = styled(Typography)({
  fontSize: "40px",
  fontFamily: "Poppins",
  color: palette.white.light,
  lineHeight: "24px",
  marginBottom: "10px",
  fontWeight: 500,
  zIndex: 2,
  marginTop: "12px",

  ["@media (max-width: 1399px)"]: {
    fontSize: "30px",
    marginTop: "6px",
  }
});

export const PrestationDescription = styled(Typography)({
  color: palette.white.light,
  fontSize: "20px",
  fontFamily: "Poppins",
  fontWeight: 500,
  zIndex: 2,

  ["@media (max-width: 1399px)"]: {
    fontSize: "16px",
  }
});

// section four

export const SectionFourContainer = styled(Grid)({
  width: "calc(100% - 48px)",
  padding: "0 65px",
  display: "flex",


  ["@media (max-width: 1199px)"]: {
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    padding: "0 24px",
  },
});

export const Metric = styled(Grid)({
  width: "100%",
  maxWidth: "215px",
  
  ["@media (max-width: 1199px)"]: {
    maxWidth: "95%",
  },
});

export const MetricContainer = styled(Grid)({
  display: "flex",
  justifyContent: "center",

  "&:first-of-type": {
    justifyContent: "flex-start"
  },

  "&:last-of-type": {
    justifyContent: "flex-end"
  },
  
  ["@media (max-width: 1199px)"]: {
    justifyContent: "flex-start",
    marginBottom: "24px",

    "&:nth-of-type(2)": {
      justifyContent: "flex-end"
    },
  
  },
})

export const TitleMedric = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "46px",
  lineHeight: "120%",
  letterSpacing: "-1px",
  color: palette.black.main_8,
  paddingBottom: "10px",
  borderBottom: "5px solid " + palette.green.button,
  width: "100%",
  
  ["@media (max-width: 1199px)"]: {
    fontSize: "20px",
    paddingBottom: "0px",
  },
});

export const DetailsMedric = styled(Typography)({
  marginTop: "10px",
  fontFamily: "Poppins",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: " rgba(10, 15, 13, 0.8)",
  
  ["@media (max-width: 1199px)"]: {
    marginTop: "0px",
  },
});

// section five

export const SectionFiveContainer = styled(Grid)({
  backgroundColor: "#F0F3F5",
  position: "relative",
  width: "100vw",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderBottom: "1px solid #000",
  borderTop: "1px solid #000",
  width: "100%",
  paddingTop: "24px",
  overflow: "hidden",
});

export const AbstractGreenImage = styled("img")({
  position: "absolute",
  right: "-30px",
  bottom: "150px"
});

export const AbstractGreenImage2 = styled("img")({
  position: "absolute",
  right: "-10px",
  top: "100px",
  height: "89px"
});

export const AbstractPinkImage = styled("img")({
  position: "absolute",
  left: "-50px",
  bottom: "-5px"
});

export const AbstractPinkImage2 = styled("img")({
  position: "absolute",
  left: "-45px",
  bottom: "-2px",
  height: "85px"
});

export const AbstractPinkImage3 = styled("img")({
  position: "absolute",
  left: "-40px",
  top: "250px",
  height: "85px",
  rotate: "50deg"
});

export const TitleSectionFive = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "48px",
  lineHeight: "120%",
  letterSpacing: "-1px",
  color: "#323232" ,
  textAlign: "left",
  width: "calc(100% - 178px)",
  zIndex: 2,

  ["@media (max-width: 1199px)"]: {
    width: "calc(100% - 48px)",
    fontSize: "32px",
  },
});

export const DescriptionSectionFive = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "120%",
  letterSpacing: "-1px",
  color: "#545861" ,
  textAlign: "left",
  width: "calc(100% - 178px)",
  marginTop: "10px",
  zIndex: 2,

  ["@media (max-width: 1199px)"]: {
    width: "calc(100% - 48px)",
    fontSize: "20px",
  },
})

export const TitlePink = styled("span")({
  color: "#F92F5F"
})

export const ContainerAnimation = styled(Grid)({
  width: "100%",
  maxWidth: "1300px",
  margin: "0 auto",
  justifyContent: "center",
  position: "relative",
  height: "360px",
  marginBottom: "-100px"
})

export const CardLeft = styled("img")({
  position: "absolute",
  zIndex: 2,
  width: "237px",
  top: "calc(50% + 30px)",
  left: 0,
  transform: "translateY(-50%)"
})

export const RedLine = styled(Box)({
  borderRadius: "20px",
  backgroundColor: palette.red.light,
  position: "absolute",
  left: "30%",
  top: "50%",
  transform: "translateX(100%)",
  zIndex: 2,
  width: "82px",
  height: "15px"
})

export const CardRight = styled("img")({
  height: "50px",
  position: "absolute",
  zIndex: 2,
  right: "1%"
})

export const ContainerButtons = styled(Grid)({
  maxWidth: "627px",
  width: '100%',
  flexDirection: "column",
  position: "absolute",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  bottom: "0",
  left: "50%",
  transform: "translateX(-50%)",
  padding: "0px 16px",

  "& p:first-of-type": {
    maxWidth: "527px",
  }
})

export const WrapperButtons = styled(Grid)({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  margin: "40px 0"
})


export const AnimationHorizontalNeedPill = keyframes({
  "0%": {
    top: "50%",
    left: "120px",
    opacity: 0,
  },
  "7%": {
    top: "47%",
    opacity: 1,
  },
  "20%": {
    opacity: 1,
    top: "47%",
  },
  "30%": {
    opacity: 0,
    top: "50%",
    left: "36%",
  },
})

export const AnimationHorizontalResponsePill = keyframes({
  "80%": {
    top: "50%",
    left: "36%",
    opacity: 0,
  },
  "87%": {
    top: "52%",
    opacity: 1,
  },
  "99.5%": {
    opacity: 1,
    top: "52%",
  },
  "100%": {
    opacity: 0,
    top: "50%",
    left: "120px",
  },
})

export const AnimationInterimNeedPill = keyframes({
  "30%": {
    top: "50%",
    left: "36%",
    opacity: 0,
    transform: "rotate(-20deg)"
  },
  "30.5%": {
    top: "47.5%",
    opacity: 1,
  },
  "60%": {
    opacity: 1,
  },
  "70%": {
    opacity: 0,
    left: "calc(100% - 230px)",
    top: "5px"
  },
})

export const AnimationInterimResponsePill = keyframes({
  "55%": {
    top: "30px",
    opacity: 0,
    left: "calc(100% - 230px)",
    transform: "rotate(-15deg)"
  },
  "55.5%": {
    opacity: 1,
  },
  "80%": {
    opacity: 1,
    transform: "rotate(-10deg)", 
  },
  "88%": {
    opacity: 0,
    top: "50%",
  },
  "95%": {
    top: "50%",
    left: "36%",
  },
})

export const AnimationRecruteursNeedPill = keyframes({
  "30%": {
    top: "50%",
    left: "36%",
    opacity: 0,
    transform: "rotate(-15deg)"
  },
  "30.5%": {
    top: "47.5%",
    opacity: 1,
  },
  "60%": {
    opacity: 1,
  },
  "70%": {
    opacity: 0,
    top: "66px",
    left: "calc(100% - 230px)",
  },
})

export const AnimationRecruteursResponsePill = keyframes({
  "55%": {
    top: "96px",
    opacity: 0,
    left: "calc(100% - 230px)",
    transform: "rotate(-10deg)"
  },
  "55.5%": {
    opacity: 1,
  },
  "80%": {
    opacity: 1,
    transform: "rotate(-10deg)",
  },
  "88%": {
    opacity: 0,
    top: "50%",
  },
  "95%": {
    top: "50%",
    left: "36%",
  },
})


export const AnimationPrestasNeedPill = keyframes({
  "30%": {
    top: "50%",
    left: "36%",
    opacity: 0,
    transform: "rotate(-8deg)"
  },
  "30.5%": {
    top: "48%",
    opacity: 1,
  },
  "60%": {
    opacity: 1,
  },
  "70%": {
    opacity: 0,
    top: "131px",
    left: "calc(100% - 230px)",
  },
})

export const AnimationPrestasResponsePill = keyframes({
  "55%": {
    top: "162px",
    opacity: 0,
    left: "calc(100% - 230px)",
    transform: "rotate(0deg)"
  },
  "55.5%": {
    opacity: 1,
  },
  "80%": {
    opacity: 1,
    transform: "rotate(-10deg)"
  },
  "88%": {
    opacity: 0,
    top: "50%",
  },
  "95%": {
    top: "50%",
    left: "36%",
  },
})

export const AnimationJoboardNeedPill = keyframes({
  "30%": {
    top: "50%",
    left: "36%",
    opacity: 0,
    transform: "rotate(8deg)"
  },
  "30.5%": {
    top: "49%",
    opacity: 1,
  },
  "60%": {
    opacity: 1,
  },
  "70%": {
    opacity: 0,
    top: "197px",
    left: "calc(100% - 230px)",
  },
})

export const AnimationJoboardResponsePill = keyframes({
  "55%": {
    top: "228px",
    opacity: 0,
    left: "calc(100% - 230px)",
    transform: "rotate(5deg)"
  },
  "55.5%": {
    opacity: 1,
  },
  "80%": {
    opacity: 1,
  },
  "88%": {
    opacity: 0,
  },
  "95%": {
    top: "48%",
    left: "36%",
  },
})

export const AnimationFreelancesNeedPill = keyframes({
  "30%": {
    top: "50%",
    left: "36%",
    opacity: 0,
    transform: "rotate(15deg)"
  },
  "30.5%": {
    top: "49%",
    opacity: 1,
  },
  "60%": {
    opacity: 1,
  },
  "70%": {
    opacity: 0,
    top: "273px",
    left: "calc(100% - 230px)",
  },
})


export const AnimationFreelancesResponsePill = keyframes({
  "55%": {
    top: "294px",
    opacity: 0,
    left: "calc(100% - 230px)",
    transform: "rotate(18deg)"
  },
  "55.5%": {
    opacity: 1,
  },
  "80%": {
    opacity: 1,
  },
  "88%": {
    opacity: 0,
  },
  "95%": {
    top: "48%",
    left: "36%",
  },
})


export const AnimationAssosNeedPill = keyframes({
  "30%": {
    top: "50%",
    left: "36%",
    opacity: 0,
    transform: "rotate(28deg)"
  },
  "30.5%": {
    top: "48%",
    opacity: 1,
  },
  "60%": {
    opacity: 1,
  },
  "70%": {
    opacity: 0,
    top: "329px",
    left: "calc(100% - 230px)",
  },
})

export const AnimationAssosResponsePill = keyframes({
  "55%": {
    top: "360px",
    opacity: 0,
    left: "calc(100% - 230px)",
    transform: "rotate(28deg)"
  },
  "55.5%": {
    opacity: 1,
  },
  "80%": {
    opacity: 1,
  },
  "88%": {
    opacity: 0,
  },
  "95%": {
    top: "48%",
    left: "36%",
  },
})


// section five mobile

export const ContainerSection1 = styled(Grid)({
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  maxWidth: "100vw"
})

export const CardTop = styled("img")({
  marginBottom: "50px",
  zIndex: 2
})

export const CardBottom = styled("img")({
  width: "108px",
  zIndex: 2
})

export const YllonaLogo = styled("img")({
  width: "114px"
})

export const RedLineMobile = styled(Box)({
  borderRadius: "20px",
  backgroundColor: palette.red.light,
  position: "absolute",
  width: "10px",
  height: "38px",
  left: "50%",
  zIndex: 2,
  top: "300px",
  transform: "translateX(-50%)"
})

export const ContainerBottomCards = styled(Grid)({
  top: "calc(100% + 205px)",
  flexDirection: "column",
})

export const ContainerTwoBottomCards = styled(Grid)({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%"
})

export const AnimationNeedPill = keyframes({
  "0%": {
    top: "220px",
    left: "46%",
    opacity: 0,
    transform: "rotate(90deg)",
  },
  "7%": {
    left: "50%",
    opacity: 1,
  },
  "30%": {
    opacity: 0,
    left: "46%",
    top: "300px",
    transform: "rotate(90deg)",
  },
})

export const AnimationResponsePill = keyframes({
  "70%": {
    top: "220px",
    right: "44%",
    opacity: 0,
    transform: "rotate(-90deg)",
  },
  "77%": {
    right: "46%",
    opacity: 1,
  },
  "90%": {
    opacity: 1,
    right: "46%",
  },
  "100%": {
    opacity: 0,
    right: "44%",
    top: "300px",
    transform: "rotate(-90deg)",
  },
})

export const AnimationInterimNeedPillMobile = keyframes({
  "30%": {
    top: "303px",
    right: "48%",
    opacity: 0,
    rotate: "55deg",
  },
  "48%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0,
    top: "465px",
    right: "10%",
  },
})

export const AnimationInterimResponsePillMobile = keyframes({
  "50%": {
    top: "498px",
    opacity: 0,
    right: "10%",
    rotate: "60deg"
  },
  "50.5%": {
    opacity: 1,
  },
  "68%": {
    opacity: 0,
  },
  "70%": {
    top: "313px",
    right: "48%",
  },
})

export const AnimationRecruteursNeedPillMobile = keyframes({
  "30%": {
    top: "303px",
    left: "48%",
    opacity: 0,
    rotate: "-55deg",
  },
  "48%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0,
    top: "465px",
    left: "10%",
  },
})

export const AnimationRecruteursResponsePillMobile = keyframes({
  "50%": {
    top: "498px",
    opacity: 0,
    left: "10%",
    rotate: "-65deg"
  },
  "50.5%": {
    opacity: 1,
  },
  "68%": {
    opacity: 0,
  },
  "70%": {
    top: "313px",
    left: "48%",
  },
})

export const AnimationSousTraitantsNeedPillMobile = keyframes({
  "30%": {
    top: "303px",
    right: "40%",
    opacity: 0,
    rotate: "90deg",
  },
  "30.5%": {
    opacity: 1,
  },
  "35%": {
    top: "521px",
    rotate: "90deg",
    right: "38%",
  },
  "37%": {
    opacity: 1,
    rotate: "55deg",
  },
  "50%": {
    opacity: 0,
    top: "632px",
    right: "10%",
    rotate: "40deg",
  },
})

export const AnimationSousTraitantsResponsePillMobile = keyframes({
  "50%": {
    top: "665px",
    opacity: 0,
    right: "24%",
    rotate: "55deg"
  },
  "50.5%": {
    opacity: 1,
  },
  "55%": {
    top: "521px",
    right: "44%",
  },
  "62%": {
    opacity: 1,
    rotate: "90deg",
    right: "44%",
  },
  "70%": {
    opacity: 0,
    top: "303px",
    right: "38%",
    rotate: "90deg"
  },
})

export const AnimationAssociationsNeedPillMobile = keyframes({
  "30%": {
    top: "321px",
    right: "40%",
    opacity: 0,
    rotate: "90deg",
  },
  "30.5%": {
    opacity: 1,
  },
  "35%": {
    top: "521px",
    rotate: "90deg",
    right: "38%",
  },
  "37%": {
    opacity: 1,
    rotate: "-55deg",
  },
  "50%": {
    opacity: 0,
    top: "632px",
    right: "75%",
  },
})


export const AnimationAssociationsResponsePillMobile = keyframes({
  "50%": {
    top: "665px",
    opacity: 0,
    right: "80%",
    rotate: "-55deg"
  },
  "50.5%": {
    opacity: 1,
  },
  "57%": {
    top: "521px",
    right: "44%",
  },
  "60%": {
    opacity: 1,
    rotate: "-90deg",
    right: "44%",
  },
  "70%": {
    opacity: 0,
    top: "313px",
    right: "38%",
    rotate: "-90deg"
  },
})

export const AnimationFreelancesNeedPillMobile = keyframes({
  "30%": {
    top: "303px",
    right: "40%",
    opacity: 0,
    rotate: "90deg",
  },
  "30.5%": {
    opacity: 1,
  },
  "35%": {
    top: "700px",
    rotate: "90deg",
    right: "38%",
  },
  "37%": {
    opacity: 1,
    rotate: "55deg",
  },
  "50%": {
    opacity: 0,
    top: "850px",
    right: "10%",
    rotate: "40deg",
  },
})


export const AnimationFreelancesResponsePillMobile = keyframes({
  "50%": {
    top: "883px",
    opacity: 0,
    right: "24%",
    rotate: "55deg"
  },
  "50.5%": {
    opacity: 1,
  },
  "55%": {
    top: "521px",
    right: "44%",
  },
  "62%": {
    opacity: 1,
    rotate: "90deg",
    right: "44%",
  },
  "70%": {
    opacity: 0,
    top: "303px",
    right: "38%",
    rotate: "90deg"
  },
})


export const AnimationJoboardNeedPillMobile = keyframes({
  "30%": {
    top: "303px",
    right: "40%",
    opacity: 0,
    rotate: "90deg",
  },
  "30.5%": {
    opacity: 1,
  },
  "35%": {
    top: "700px",
    rotate: "90deg",
    right: "38%",
  },
  "37%": {
    opacity: 1,
    rotate: "-55deg",
  },
  "50%": {
    opacity: 0,
    top: "850px",
    right: "75%",
  },
})


export const AnimationJoboardsResponsePillMobile = keyframes({
  "50%": {
    top: "883px",
    opacity: 0,
    right: "75%",
    rotate: "55deg"
  },
  "50.5%": {
    opacity: 1,
  },
  "55%": {
    top: "521px",
    right: "44%",
  },
  "62%": {
    opacity: 1,
    rotate: "90deg",
    right: "44%",
  },
  "70%": {
    opacity: 0,
    top: "303px",
    right: "38%",
    rotate: "90deg"
  },
})

// Section Six

export const SectionSixContainer = styled(Grid)({
  width: "100vw",
  padding: "130px 65px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#F0F0F0",

  ["@media (max-width: 1199px)"]: {
    padding: "130px 24px",
    width: "100%"
  },
});

export const TitleSix = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "48px",
  lineHeight: "120%",
  letterSpacing: "-1px",
  color: palette.black.main_8,
  marginTop: "60px",
  marginBottom: "46px",
  width: "100%",

  ["@media (max-width: 1199px)"]: {
    fontSize: "32px",
  },
});

export const BoxContainer = styled(Grid)({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "300px",
  maxWidth: "90%",
});

export const BlackBox = styled(Box)({
  backgroundColor: "#0A0F0D",
  color: "#F0F3F5",
  padding: "18px 32px",
  borderRadius: "16px",
  width: "calc(100% - 64px)",
  maxWidth: "300px"
});

export const TitleBlackBox = styled(Typography)({
  fontSize: "16px",
  fontWeight: 600,
  fontFamily: "Poppins"
});

export const DescriptionBlackBox = styled(Typography)({
  fontSize: "12px",
  fontWeight: 600,
  fontFamily: "Poppins"
});

export const WhiteBox = styled(Box)({
  backgroundColor: "#F6F9FA",
  padding: "29px 20px 12px 20px",
  borderRadius: "16px",
  width: "calc(100% - 40px)",
  maxWidth: "300px",
  border: "1px solid " + palette.grey.placeholder
});

export const PlaceTexteWhiteContainer = styled(Grid)({
  padding: "0 12px 20px 12px",
  borderBottom: "1px solid #AFAFAF"
})

export const PlaceTextWhiteBox = styled(Typography)({
  fontSize: "16px",
  fontWeight: 600,
  fontFamily: "Poppins",
});

export const PlaceDescriptionTextWhiteBox = styled(Typography)({
  fontSize: "12px",
  fontWeight: 400,
  fontFamily: "Poppins",
})

export const IconsWhiteContainer = styled(Grid)({
  padding: "0 12px 20px 12px",
});

export const IconWhiteContainer = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "center",
  marginTop: "30px",
  width: "100%"
});

export const IconWhite = styled("img")({

});

export const IconDescriptionContainer = styled(Box)({
  marginLeft: "12px",
})

export const TitleIconWhite = styled(Typography)({
  fontSize: "10px",
  fontWeight: "500",
  fontFamily: "Poppins",
  marginBottom: "8px"
});

export const DescriptionIconWhite = styled(Typography)({
  fontSize: "8px",
  fontWeight: "500",
  fontFamily: "Poppins",
});

export const PartenairesImage = styled("img")({
  height: "100%",
  maxWidth: "100%",
});

export const SectionSixBoxContainer = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "flex-start",
  justifyContent: "space-between"
});

export const TitleSixContainer = styled(Typography)({
  fontFamily: 'Poppins',
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "36px",
  lineHeight: "120%",
  letterSpacing: "-1px",

  color: palette.black.main_8,

  "& span": {
    color: "#F03964"
  }
});

export const ContainerDescriptionSix = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  marginTop: "26px"
});

export const IconDescriptionSix = styled("img")({
  marginTop: "6px"
});

export const TextDescriptionContainerSix = styled(Box)({
  marginLeft: "23px"
});

export const TitleDescripionSix = styled(Typography)({
  fontFamily: 'Poppins',
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "30px",
})

export const DescriptionSix = styled(Typography)({
  fontFamily: 'Poppins',
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px%",
})


// section seven

export const SectionSevenContainer = styled(Grid)({
  backgroundColor: "#011E36",
  width: "100vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
});


export const SectionSevenContent = styled(Grid)({
  width: "calc(100% - 48px)",
  padding: "0 65px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  
  ["@media (max-width: 1199px)"]: {
    width: "100%",
    padding: "0 24px"
  },
})

export const TitleSeven = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "48px",
  lineHeight: "120%",
  letterSpacing: "-1px",
  color: palette.white.pattern,
  margin: "60px auto 46px auto",
  width: "100%",
  textAlign: "left",
  
  ["@media (max-width: 1199px)"]: {
    fontSize: "32px",
  },
});

export const ImageSevenDesktop = styled("img")({
  padding: "0 30px",
  maxWidth: "1370px",
  width: "95%",
  margin: "0 auto",

  ["@media (max-width: 1199px)"]: {
    display: "none"
  },
});

export const ImageSevenTablet = styled("img")({
  padding: "0 30px",
  maxWidth: "850px",
  width: "95%",
  display: "none",
  margin: "0 auto",

  ["@media (max-width: 1199px)"]: {
    display: "block"
  },
  ["@media (max-width: 899px)"]: {
    display: "none"
  },
});

export const ImageSevenMobile = styled("img")({
  padding: "0 30px",
  maxWidth: "206px",
  width: "95%",
  display: "none",
  margin: "0 auto",

  ["@media (max-width: 1199px)"]: {
    display: "none"
  },
  ["@media (max-width: 899px)"]: {
    display: "block"
  },
});

export const ButtonRed = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "150%",
  color: palette.white.pattern,
  padding: "16px 32px",
  backgroundColor: "#F03964",
  borderRadius: "16px",
  marginTop: "38px",

  "&:hover": {
    backgroundColor: "#F03964",
  }
});

export const TextSeven = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "48px",
  lineHeight: "120%",
  color: palette.white.pattern,
  position: "absolute",
  top: "15%",
  left: "34%",
  maxWidth: "45%",
  marginLeft: "20px",
  
  ["@media (max-width: 1199px)"]: {
    top: "15%",
    left: "28%",
  },
  
  ["@media (max-width: 899px)"]: {
    display: "none",
  },
})

// section eight

export const ImageComment = styled("img")({
  height: "450px",
  maxWidth: "100%",
  objectFit: "contain",
  
  ["@media (max-width: 899px)"]: {
    maxWidth: "260px",
  },
})

// section nine

export const TitleNine = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "48px",
  lineHeight: "120%",
  letterSpacing: "-1px",
  color: palette.black.main_8,
  margin: "60px auto 46px auto",
  width: "100%",
  textAlign: "left",

  ["@media (max-width: 1199px)"]: {
    fontSize: "32px",
  },
});

export const StepsContainer = styled(Grid)({
  width: "100%",
  flexDirection: "row",
  borderTop: "1px dashed " + palette.grey.border,
  justifyContent: "space-between",
  position: "relative"
});


const animationStep1 = keyframes`
  0% {
    opacity: 0
  }
  1.5% {
    opacity: 0
  }
  33% {
    opacity: 1
  }
`

export const Step1 = styled(Grid)({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  marginTop: "-55px",
  position: "relative",
  // animation: animationStep1 + " 5s linear",
  zIndex: 2
});

const animationStep2 = keyframes`
  0% {
    opacity: 0
  }
  33% {
    opacity: 0
  }
  33.5% {
    opacity: 0
  }
  66% {
    opacity: 1
  }
`

export const Step2 = styled(Grid)({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  marginTop: "-55px",
  position: "relative",
  // animation: animationStep2 + " 5s linear",
  zIndex: 2
});

const animationStep3 = keyframes`
  0% {
    opacity: 0
  }
  66% {
    opacity: 0
  }
  66.5% {
    opacity: 0
  }
  99% {
    opacity: 1
  }
`


export const Step3 = styled(Grid)({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  marginTop: "-55px",
  position: "relative",
  // animation: animationStep3 + " 5s linear",
  zIndex: 2
});

export const NumberContainer = styled(Grid)({
  width: "fit-content",
  flexDirection: "column",
  display: "flex",
})

export const Number = styled(Typography)({
  fontSize: "36px",
  lineHeight: "43px",
  fontWeight: 500,
  letterSpacing: "-1px",
  fontFamily: "Poppins",

  ["@media (max-width: 1199px)"]: {
    fontSize: "32px",
  },
});

export const GreenCircle = styled(Box)({
  border: "4px solid #32F1C4",
  width: "15px",
  height: "15px",
  borderRadius: "50%",
  backgroundColor: palette.white.pattern,
  zIndex: 2
});

export const SectionNineContainer = styled(Grid)({
  width: "calc(100% - 48px)",
  padding: "0 65px",
  display: "flex",
  flexDirection: "column",

  ["@media (max-width: 1199px)"]: {
    width: "100%",
    padding: "0 24px",
  },

});

export const ImageStep = styled("img")({
  marginTop: "34px",
  maxWidth: "95%",
  objectFit: "contain"
});

export const WhiteSpaceLeft = styled(Box)({
  position: "absolute",
  width: "16%",
  height: "5px",
  backgroundColor: palette.white.pattern,
  top: "-2px",
  left: 0,
  zIndex: 1
});

export const WhiteSpaceRight = styled(Box)({
  position: "absolute",
  width: "16%",
  height: "5px",
  backgroundColor: palette.white.pattern,
  top: "-2px",
  right: 0,
  zIndex: 1
});

// section ten

export const SectionTenCard = styled(Grid)({
  border: "1px solid " + palette.black.main_8,
  backgroundColor: "#F0F3F5",
  padding: "30px 64px",
  borderRadius: "15px",

  ["@media (max-width: 1199px)"]: {
    padding: "24px",
  },
});

export const TenCardDescription = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "22px",
  lineHeight: "32px",
  letterSpacing: "-2%",
  fontWeight: 400,

  ["@media (max-width: 1199px)"]: {
    fontSize: "18px",
  },
});

export const ButtonGreen2 = styled(Button)({
  backgroundColor: palette.green.button,
  borderRadius: "47px",
  padding: "14px 32px",
  fontFamily: "Poppins",
  fontSize: "22px",
  lineHeight: "32px",
  letterSpacing: "-1px",
  fontWeight: 700,
  color: palette.blue.background_2,
  marginTop: "16px",

  "&:hover" :{
    backgroundColor: palette.green.button,
  },

  ["@media (max-width: 1199px)"]: {
    fontSize: "18px",
    borderRadius: "16px",
  },
});

//  section eleven

export const SectionElevenContainer = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "41px auto 0 auto",
  width: "calc(100% - 178px)",
  padding: "106px 65px 230px 65px",

  ["@media (max-width: 1199px)"]: {
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    padding: "24px",
  },
});

export const TitleEleven = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "48px",
  lineHeight: "120%",
  letterSpacing: "-3px",
  width: "100%",
  textAlign: "center",

  ["@media (max-width: 1199px)"]: {
    width: "100%",
    textAlign: "center",
    fontSize: "32px",
  },
})

export const ToggleButtonGroupFaq = styled(ToggleButtonGroup)({
  padding: "8px",
  backgroundColor: "#F4F4F6",
  borderRadius: "8px",
  marginTop: "41px",

  ["@media (max-width: 899px)"]: {
    width: "100%",
  },
});

export const ToggleButtonFaq = styled(ToggleButton)({
  backgroundColor: "transparent",
  color: "#131316",
  padding: "12px 24px",
  borderRadius: "8px !important",
  border: "none",

  "&.Mui-selected": {
    backgroundColor: "#32F1C4",
    color: palette.blue.background_2,

    "&:hover": {
      backgroundColor: "#32F1C4",
      color: palette.white.pattern,
    }
  },

  ["@media (max-width: 1199px)"]: {
    width: "100%",
  },

  ["@media (max-width: 380px)"]: {
    padding: "6px 6px",
  },
});

export const AccordionStyle = styled(Accordion)({
  
})

// footer

export const FooterContainer = styled(Grid)({
  backgroundColor: "#011E36",
  width: "100vw"
});

export const FooterContent = styled(Grid)({
  width: "calc(100% - 48px)",
  padding: "69px 65px 40px 65px",

  ["@media (max-width: 1199px)"]: {
    width: "100%",
    padding: "24px",
  },
});

export const ContainerFooterItems = styled(Grid)({
  flexDirection: "row",
  width: "100%",
});

export const Copyright = styled(Typography)({
  color: "#BCC2C3",
  fontFamily: "Poppins",
  fontSize: "14px",
  lineHeight: "150%",
  fontWeight: 400,
  width: "100%",
  marginTop: "65px",

  ["@media (max-width: 1199px)"]: {
    paddingTop: "16px",
    marginTop: "16px",
    borderTop: "1px solid " + palette.grey.border,
    textAlign: "center"
  },
});

export const NewsletterContainer = styled(Grid)({
  width: "406px",
  padding: "24px", 
  backgroundColor: "#F4F4F6",
  borderRadius: "16px"
});

export const TitleNews = styled(Typography)({
  color: "#35333C",
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "150%"
});

export const TitleDescripion = styled(Typography)({
  color: "#35333C",
  fontFamily: "Poppins",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "150%"
});

export const InputNewsletterContainer = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between"
});

export const InputNewsletter = styled(TextField)({
  width: "100%",
  maxWidth: "220px", 

  "& .MuiOutlinedInput-root": {
    boxShadow: boxShadow.grey.main_3,
    height: "36px",
    borderRadius: "8px",
    width: "100%",

    "& .MuiInputBase-input": {
      fontFamily: 'Roboto',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "150%",
      backgroundColor: "transparent",
      borderRadius: "8px",
  
      "&:hover": {
        borderRadius: "6px",
      },

      "&:-internal-autofill-selected": {
        backgroundColor: "transparent",
      }
    },

    "& fieldset": {
      height: "40px",
      border: "1px solid #D7D7D7",
    },

    "&.Mui-focused fieldset": {
      border: "1px solid #4E253F",
    }
  }
});

export const ButtonNewsletter = styled(Button)({
  fontFamily: 'Roboto',
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "150%",
  backgroundColor: "#32F1C4",
  height: "36px",
  padding: "12px 20px",
  color: palette.white.pattern,
  borderRadius: "8px",

  "&:hover": {
    backgroundColor: "#32F1C4",
  }
});

export const ButtonsNavigationContainer = styled(Grid)({
  flexDirection: "row",
  padding: "0",
  paddingLeft: "5%",
  width: "calc(100% - 406px)"
});

export const ButtonsNavigationContent = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start"
});

export const TitleNavigation = styled(Typography)({
  fontFamily: 'Roboto',
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "120%",
  color: palette.white.pattern,
  marginBottom: "6px"
});

export const ButtonNavigate = styled(Button)({
  fontFamily: 'Roboto',
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "120%",
  color: palette.white.pattern,
  backgroundColor: "transparent",
  padding: "8px 0",
  minWidth: "fit-content",

  "&:hover":{
    backgroundColor: "transparent"
  }
})

export const ButtonLink = styled("a")({
  fontFamily: 'Roboto',
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "120%",
  color: palette.white.pattern,
  backgroundColor: "transparent",
  padding: "8px 0",
  textDecoration: "none",

  "&:hover":{
    backgroundColor: "transparent"
  }
});

export const ContainerSocialLinks = styled(Grid)({
  marginTop: "27px",

  ["@media (max-width: 1199px)"]: {
    marginTop: "0px",
    marginLeft: "16px"
  },
});

export const SocialLink = styled(IconButton)({
  padding: 0,
  backgroundColor: "transparent",
  marginRight: "16px",

  "&:hover":{
    backgroundColor: "transparent"
  }
});

export const SocialLinkImage = styled("img")({

})