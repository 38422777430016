export default {
  black: {
    main: "#000000",
    main_3: "#666666",
    main_4: "#858585",
    main_5: "#BDBDBD",
    main_6: "#E0E0E0",
    main_7: "#1D1C1D",
    main_8: "#323232",
    main_9: "#131313",
    main_10: "#131316"
  },
  blue: {
    main: "#003E55",
    dark: "#023143",
    light: "#005574",
    background: "#96BCCA",
    background_1: "#F0F7FF",
    background_2: "#011E36"
  },
  gold: {
    main: "#704B14",
    dark: "#52370F",
    light: "#D18D26",
  },
  green: {
    main: "#31D1AB",
    dark: "#25BB97",
    dark_1: "#020D03",
    dark_2: "#080F09",
    light: "#31F3C5",
    background: "#ABE4D7",
    background_1: "#58D6B9",
    button: "#32F1C4",
    border: "#00D8A4",
  },
  grey: {
    main: "#E0E0E0",
    medium: "#D9D9D9",
    light: "#F9F9F9",
    border: "#DADADA",
    placeholder: "#BEBEBE",
    text: "#7E7E7E",
    icon: "#3D3C42"
  },
  primary: {
    main: "#3F46D6",
  },
  red: {
    main: "#D51042",
    dark: "#AF012E",
    light: "#F82F62",
    warning: "#C60D0D",
  },
  white: {
    main: "#F4F8FB",
    dark: "#DEE5EB",
    light: "#FCFCFD",
    snow: "#F8F8F8",
    rgba68: "rgba(255, 255, 255, 0.68)",
    pattern: "#FFFFFF",
    main_2: "#F5F5F5"
  },
};
