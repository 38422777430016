import React, { useEffect, useState } from "react";
import * as style from "./styles";
import { Box } from "@mui/material";
import { SectionElevenContainer } from "./sections/section-eleven";

import { HomeHeader } from "../../components/headers";
import { HomeSection1 } from "./sections/section-1";
import { HomeSection1Mobile } from "./sections/section-1-mobile";

export const ComingSoon = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 899);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 899);
  };
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollPositionControl, setScrollPositionControl] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, [isDesktop]);

  const handleScroll = () => {    
    const position = window.pageYOffset;
    setScrollPosition(position);
  }
  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("resize", handleScroll);
  }, []);

  useEffect(() => {
    if (scrollPosition > scrollPositionControl) {
      // let temp = document.getElementById("faq")
      // temp.scrollIntoView({behavior: 'smooth', block: "start", inline: "nearest"})
    } 
  }, [scrollPosition])

  return(
    <style.Home id="home">
      <Box id="top">
        <HomeHeader />
        <Box
          pt="116px"
          // sx={{
          //   backgroundImage: "radial-gradient(56.76% 56.76% at 50% 50%, rgba(247, 251, 255, 0.32) 31.25%, #F5FAFF 100%)"
          // }}
        >
          { isDesktop ? (
              <HomeSection1 display={{xs: "none", md: "flex"}}/>
            ) : (
              <HomeSection1Mobile display={{xs: "flex", md: "none"}} />
            )
          }        
        </Box>
      </Box>
      <Box id="faq">
        <SectionElevenContainer />
      </Box>
    </style.Home>
  )
}