import { Box, Grid, Typography } from "@mui/material";
import { styled, keyframes } from "@mui/system";
import palette from "../../../../theme/palette";


export const ContainerSection1 = styled(Grid)({
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  maxWidth: "100vw"
})

export const CardTop = styled(Grid)({
  marginBottom: "50px",
  zIndex: 2,
  width: "145px"
});

export const CardTopWhite = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  padding: "6px 10px",
  width: "100%",
  alignItems: "cemter",
  backgroundColor: palette.white.light,
  boxShadow: "0px 8px 24px -4px rgba(27, 46, 94, 0.08)",
  borderRadius: "10px"
});

export const CardTopImage = styled("img")({
  width: "31px",
  marginRight: "6px"
});

export const CardTopText = styled(Typography)({
  color: palette.blue.dark,
  fontFamily: "Poppins",
  fontWeight: 400,
  fontSize: "10px",
  lineHeight: "15px"
});

export const CardTopDescription = styled(Typography)({
  color: palette.black.main_4,
  fontFamily: "Poppins",
  fontWeight: 400,
  fontSize: "10px",
  lineHeight: "15px"
});

export const CardTopRed = styled(Typography)({
  color: palette.white.snow,
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "17px",
  width: "100%",
  textAlign: "center",
  background: "linear-gradient(109deg, rgba(242, 114, 144, 0.9) 4.78%, rgba(248, 47, 98, 0.9) 74.77%, rgba(245, 83, 123, 0.9) 100%)",
  boxShadow: "0px 8px 24px -4px rgba(27, 46, 94, 0.07)",
  backdropFilter: "blur(8px)",
  borderRadius: "10px",
  marginTop: "8px",
  padding: "4px 0"
})

export const CardTalent = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "106px",
  zIndex: 2,
  backgroundColor: "#FFFFFF",
  border: "0.5px solid #D7D7D7",
  boxShadow: "0px 8px 24px -4px rgba(27, 46, 94, 0.08)",
  borderRadius: "10px",
  padding: "20px 4px 4px 4px"
});

export const NameTalent = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: "normal",
  fontWeight: 400,
  fontSize: "10px",
  lineHeight: "1",
  color: palette.blue.background_2,
  backgroundColor: palette.green.light,
  borderRadius: "8px",
  backdropFilter: "blur(8px)",
  boxShadow: "0px 8px 24px -4px rgba(27, 46, 94, 0.07)",
  padding: "4px 0",
  marginTop: "4px",
  marginBottom: "8px",
  width: "100%",
  textAlign: "center"
});

export const DescriptionTalent = styled(Typography)({
  fontFamily: "Poppins",
  color: palette.blue.dark,
  fontWeight: 400,
  fontSize: "10px",
  lineHeight: 1,
  marginBottom: "6px",
  textAlign: "center"
})

export const ImageCardTalent = styled("img")({
  height: "23px"
});

export const NumCard = styled(Typography)({
  fontFamily: "Poppins",
  color: palette.blue.dark,
  fontWeight: 400,
  fontSize: "10px",
  lineHeight: 1,
  textAlign: "center",
  marginBottom: "4px"
})

export const CardBottom = styled("img")({
  zIndex: 2,
  border: "1px solid rgba(218,218,218,0.41)",
  borderRadius: "10px"
})

export const YllonaLogo = styled(Typography)({
  fontWeight: "900",
  fontSize: "50px",
  fontFamily: "Roboto",
})

export const RedLine = styled(Box)({
  borderRadius: "20px",
  backgroundColor: palette.red.light,
  position: "absolute",
  width: "10px",
  height: "38px",
  left: "50%",
  zIndex: 2,
  top: "calc(100% + 150px)",
  transform: "translateX(-50%)"
})

export const ContainerBottomCards = styled(Grid)({
  position: "absolute",
  top: "calc(100% + 240px)",
  flexDirection: "column",
})

export const ContainerTwoBottomCards = styled(Grid)({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%"
})

export const AnimationNeedPill = keyframes({
  "0%": {
    top: "7%",
    left: "40%",
    opacity: 0,
    transform: "rotate(90deg)",
  },
  "7%": {
    left: "44%",
    opacity: 1,
  },
  "20%": {
    opacity: 1,
    left: "44%",
  },
  "30%": {
    opacity: 0,
    left: "40%",
    top: "calc(100% + 160px)",
    transform: "rotate(90deg)",
  },
})

export const AnimationResponsePill = keyframes({
  "70%": {
    top: "calc(100% + 160px)",
    right: "40%",
    opacity: 0,
    transform: "rotate(-90deg)",
  },
  "77%": {
    right: "42%",
    opacity: 1,
  },
  "90%": {
    opacity: 1,
    right: "42%",
  },
  "95%": {
    opacity: 0,
    right: "40%",
    top: "7%",
    transform: "rotate(-90deg)",
  },
})

export const AnimationInterimNeedPillMobile = keyframes({
  "30%": {
    top: "calc(100% + 148px)",
    right: "48%",
    opacity: 0,
    rotate: "45deg",
  },
  "48%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0,
    top: "calc(100% + 253px)",
    right: "10%",
  },
})

export const AnimationInterimResponsePillMobile = keyframes({
  "50%": {
    top: "calc(100% + 285px)",
    opacity: 0,
    right: "10%",
    rotate: "55deg"
  },
  "50.5%": {
    opacity: 1,
  },
  "68%": {
    opacity: 0,
  },
  "70%": {
    top: "calc(100% + 158px)",
    right: "48%",
  },
})

export const AnimationRecruteursNeedPillMobile = keyframes({
  "30%": {
    top: "calc(100% + 148px)",
    left: "48%",
    opacity: 0,
    rotate: "-45deg",
  },
  "48%": {
    opacity: 1,
  },
  "50%": {
    opacity: 0,
    top: "calc(100% + 253px)",
    left: "10%",
  },
})

export const AnimationRecruteursResponsePillMobile = keyframes({
  "50%": {
    top: "calc(100% + 285px)",
    opacity: 0,
    left: "10%",
    rotate: "-55deg"
  },
  "50.5%": {
    opacity: 1,
  },
  "68%": {
    opacity: 0,
  },
  "70%": {
    top: "calc(100% + 158px)",
    left: "48%",
  },
})

export const AnimationSousTraitantsNeedPillMobile = keyframes({
  "30%": {
    top: "calc(100% + 160px)",
    right: "40%",
    opacity: 0,
    rotate: "90deg",
  },
  "30.5%": {
    opacity: 1,
  },
  "35%": {
    top: "calc(100% + 360px)",
    rotate: "90deg",
    right: "38%",
  },
  "37%": {
    opacity: 1,
    rotate: "40deg",
  },
  "50%": {
    opacity: 0,
    top: "calc(100% + 440px)",
    right: "10%",
    rotate: "40deg",
  },
})

export const AnimationSousTraitantsResponsePillMobile = keyframes({
  "50%": {
    top: "calc(100% + 450px)",
    opacity: 0,
    right: "24%",
    rotate: "55deg"
  },
  "50.5%": {
    opacity: 1,
  },
  "55%": {
    top: "calc(100% + 360px)",
    right: "40%",
  },
  "62%": {
    opacity: 1,
    rotate: "90deg",
    right: "40%",
  },
  "70%": {
    opacity: 0,
    top: "calc(100% + 160px)",
    right: "36%",
    rotate: "90deg"
  },
})

export const AnimationAssociationsNeedPillMobile = keyframes({
  "30%": {
    top: "calc(100% + 160px)",
    right: "40%",
    opacity: 0,
    rotate: "90deg",
  },
  "30.5%": {
    opacity: 1,
  },
  "35%": {
    top: "calc(100% + 330px)",
    rotate: "90deg",
    right: "38%",
  },
  "37%": {
    opacity: 1,
    rotate: "-40deg",
  },
  "50%": {
    opacity: 0,
    top: "calc(100% + 440px)",
    right: "75%",
  },
})


export const AnimationAssociationsResponsePillMobile = keyframes({
  "50%": {
    top: "calc(100% + 450px)",
    opacity: 0,
    right: "80%",
    rotate: "-55deg"
  },
  "50.5%": {
    opacity: 1,
  },
  "55%": {
    top: "calc(100% + 360px)",
    right: "48%",
  },
  "62%": {
    opacity: 1,
    rotate: "-90deg",
    right: "48%",
  },
  "70%": {
    opacity: 0,
    top: "calc(100% + 160px)",
    right: "46%",
    rotate: "-90deg"
  },
})

export const AnimationFreelancesNeedPillMobile = keyframes({
  "30%": {
    top: "calc(100% + 160px)",
    right: "40%",
    opacity: 0,
    rotate: "90deg",
  },
  "30.5%": {
    opacity: 1,
  },
  "35%": {
    top: "calc(100% + 550px)",
    rotate: "90deg",
    right: "38%",
  },
  "37%": {
    opacity: 1,
    rotate: "40deg",
  },
  "50%": {
    opacity: 0,
    top: "calc(100% + 630px)",
    right: "10%",
    rotate: "40deg",
  },
})


export const AnimationFreelancesResponsePillMobile = keyframes({
  "50%": {
    top: "calc(100% + 640px)",
    opacity: 0,
    right: "24%",
    rotate: "55deg"
  },
  "50.5%": {
    opacity: 1,
  },
  "55%": {
    top: "calc(100% + 550px)",
    right: "40%",
  },
  "62%": {
    opacity: 1,
    rotate: "90deg",
    right: "40%",
  },
  "70%": {
    opacity: 0,
    top: "calc(100% + 160px)",
    right: "36%",
    rotate: "90deg"
  },
})


export const AnimationJoboardNeedPillMobile = keyframes({
  "30%": {
    top: "calc(100% + 160px)",
    right: "40%",
    opacity: 0,
    rotate: "90deg",
  },
  "30.5%": {
    opacity: 1,
  },
  "35%": {
    top: "calc(100% + 520px)",
    rotate: "90deg",
    right: "38%",
  },
  "37%": {
    opacity: 1,
    rotate: "-45deg",
  },
  "50%": {
    opacity: 0,
    top: "calc(100% + 630px)",
    right: "75%",
  },
})


export const AnimationJoboardsResponsePillMobile = keyframes({
  "50%": {
    top: "calc(100% + 640px)",
    opacity: 0,
    right: "75%",
    rotate: "55deg"
  },
  "50.5%": {
    opacity: 1,
  },
  "55%": {
    top: "calc(100% + 550px)",
    right: "40%",
  },
  "62%": {
    opacity: 1,
    rotate: "90deg",
    right: "40%",
  },
  "70%": {
    opacity: 0,
    top: "calc(100% + 160px)",
    right: "36%",
    rotate: "90deg"
  },
})
