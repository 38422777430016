import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userSuccess:(state, actions) =>{
        state.user = actions.payload
      },
    userFailure:(state, actions) =>{
        state.error = actions.payload
      },
    userReset: (state) => {
        state.user = initialState.user;
        state.isLoading = initialState.isLoading;
        state.error = initialState.error;
        state.isSuccess = initialState.isSuccess;
      },
    userUpdatePending: (state) => {
        state.isLoading = true;
        state.error = "";
        state.isSuccess= false
    },
    userUpdateSuccess: (state, actions) => {
        state.isLoading = false;          
        state.error = "";
        state.isSuccess= true;
        state.user= actions.payload
    },
    userUpdateFailure: (state, actions) => {
        state.isLoading = false;
        state.error = actions.payload;
        state.isSuccess= false;
    },
  },
});

const { reducer, actions } = userSlice;

export const {
  userSuccess,
  userFailure,
  userReset,
  userUpdatePending,
  userUpdateSuccess,
  userUpdateFailure,
} = actions;


export const userActions = {
  userSuccess,
  userFailure,
  userReset,
  userUpdatePending,
  userUpdateSuccess,
  userUpdateFailure,
}

export default reducer;
