import { Typography } from "@mui/material";
import React from "react";
import palette from "../../../palette";

/* 
  Required parameters:
    id: component id
    children: component that the mouse will hover over to show the tooltip
              <Component>What stay here is the "children"</Component>

  Extra parameters:
    color: use 'color' property if you want a different color
*/

export const P20 = (props) => {
  return (
    <Typography
      {...props}
      fontFamily="Poppins"
      fontWeight={props.fontWeight || "400"}
      fontSize={props.fontSize || "20px"}
      lineHeight="30px"
      color={props.color || palette.green.dark_2}
      sx={props.sx || {
        "& b": {
          color: props.colorb || "#00D8A4"
        }
      }}
    >
      {props.children}
    </Typography>
  );
};