import React from "react";
import { PrimaryButton } from "../../buttons";
import { Box, Grid, MenuItem } from "@mui/material";
import { YllonaLargeLogo } from "../../../assets";
import * as style from "./styles";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { MobileSidebar } from "../home-sidebar-mobile";
import palette from "../../../theme/palette";
import * as font from "../../../theme/typography";
import { navigateSignIn } from "../../../utils/navigate";

export const HomeHeader = () => {
  const navigate = useNavigate();
  // const [headerType, setHeaderType] = useState("");

  // const [scrollPosition, setScrollPosition] = useState(0);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 899);
  
  const updateMedia = () => {
    setDesktop(window.innerWidth > 899);
  };
  // const handleScroll = () => {    
  //   const position = window.pageYOffset;
  //   setScrollPosition(position);
  // }

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("resize", handleScroll);
  // }, []);

  // useEffect(() => {
  //   if(scrollPosition === 0) {
  //     setHeaderType("")
  //   } else if (scrollPosition > 500) {
  //     setHeaderType("blue")
  //   } else {
  //     setHeaderType("white")
  //   }
  // }, [scrollPosition])


  return (
    <style.HeaderCustom 
      fullwidth="true" 
      container={true}
      // className={headerType}
    >
      <style.ContainerMenu 
        container={true} 
        maxWidth={{md: "850px", lg: "1290px"}}
        pt={{xs: 2.5, md: 4}}
        pr={{xs: 2.5, md: 2}}
        pb={{xs: 2.5, md: 4}}
        pl={{xs: 2.5, md: 2}}
      >   
        <Box>     
          <style.ButtonLogo 
            sx={{
              width: {
                xs: "32px", 
                md: "44px"
              }, 
              minWidth: {
                xs: "32px", 
                md: "44px"
              },
              zIndex: "99999"
            }} 
            // href={navigateSignIn}
          >
            <style.LogoImage src={YllonaLargeLogo} />
          </style.ButtonLogo>
        </Box>
      </style.ContainerMenu>
    </style.HeaderCustom>
  )
}