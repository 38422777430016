import { styled } from "@mui/system";
import { Button } from "@mui/material";
import palette from "../../../theme/palette";

export const ConnectionButtonCustom = styled(Button)({
  width: "100%",
  padding: "12px",
  textAlign: "center",
  backgroundColor: "#862165",
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.08)",
  borderRadius: "8px",

  "&:hover": {
    backgroundColor: "#571140",
    color: palette.white.pattern + " !important"
  },

  ":disabled": {
    backgroundColor: palette.grey.medium
  },

  ["@media (max-width: 899px)"]: {
    padding: "8px"
  },
});